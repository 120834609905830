import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Transfer } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import authService from '../../Services/auth/auth-service';
import perfilTecnicoService from '../../Services/perfilTecnico/perfil-tecnico-services';
import PermissaoService from '../../Services/permissoes/permissoes-service';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import MenuComponent from '../../components/menu/menu-component';
import CustomTablePagination from '../../components/pagination/pagination-component';
import permissoesService from '../../Services/permissoes/permissoes-service';
import debounce from 'lodash.debounce';
import { 
  FileCopy,
  LineWeight,
} from '@mui/icons-material';
import TimelineIcon from '@mui/icons-material/Timeline';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PasswordIcon from '@mui/icons-material/Password';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import AirplayIcon from '@mui/icons-material/Airplay';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorIcon from '@mui/icons-material/Error';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FactCheckIcon from '@mui/icons-material/FactCheck';

const getIconForPermission = (nome) => {
  switch (nome) {
    case 'Solicitação de Reversa':
      return <SettingsBackupRestoreIcon />;
    case 'Comunicação':
      return <AirplayIcon />;
    case 'Manuais':
      return <FileCopy />;
    case 'Configurar Permissões':
      return <PostAddTwoToneIcon />;
    case 'Ordens de Serviço':
      return <ListAltIcon />;
    case 'Notícias':
      return <LineWeight />;
    case 'Financeiro':
      return <AttachMoneyIcon />;
    case 'Notificações':
      return <MarkChatUnreadIcon />;
    case 'Notas Fiscais':
      return <DescriptionIcon />;
    case 'Agenda':
      return <DateRangeIcon />;
    case 'Estoque':
      return <ShoppingBagIcon  />;
    case 'Contestação da Nota Fiscal':
      return <DescriptionIcon  />;
    case 'Histórico de Estoque':
      return <TimelineIcon  />;
    case 'Técnicos':
      return <EngineeringIcon  />;
    case 'Agenda Compartilhada':
      return <EventNoteIcon  />;
    case 'Detalhes da Ordem de Serviço':
      return <ListAltIcon  />;
    case 'Senha do Supervisor':
      return <VerifiedUserIcon />;
    case 'Vistoria':
      return <FactCheckIcon />;
    case 'Contrassenha':
      return <PasswordIcon  />;
    case 'Orçamento':
      return <AddBusinessIcon />;
    case 'Fotos':
      return <PhotoLibraryIcon  />;
    case 'Base Instalada':
      return <CloudSyncIcon  />;
    case 'Ativação Integrada':
      return <IntegrationInstructionsIcon  />;
    default:
      return <ErrorIcon />;
  }
};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    position: 'relative',
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  TituloPermissao: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    textAlign: 'center',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  searchContainer: {
    marginBottom: theme.spacing(2),
  }
}));

export default function PermissoesPage() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [dadosTecnico, setDadosTecnico] = useState([]);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(true);
  const [alertMessage, setAlertMessage] = useState({ title: '', body: '' });
  const [alertType, setAlertType] = useState('success');
  const [alertOpen, setAlertOpen] = useState(false);
  const [allPermissoes, setAllPermissoes] = useState([]);
  const [openViewPermissionsDialog, setOpenViewPermissionsDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [pesquisa, setPesquisa] = useState('');
  const [allTecnicos, setAllTecnicos] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedTecnico, setSelectedTecnico] = useState(null);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [openInfoPopup, setOpenInfoPopup] = useState(false);
  const [infoMode, setInfoMode] = useState('');
  const [tecnicoPermissoes, setTecnicoPermissoes] = useState([]);
  const [openAddPermissionModal, setOpenAddPermissionModal] = useState(false);
  const [efeitoCarregandoAdicaoTecnicoPermissao, setEfeitoCarregandoAdicaoTecnicoPermissao] = useState(false);
  const [efeitoCarregandoEdicaoTecnicoPermissao, setEfeitoCarregandoEdicaoTecnicoPermissao] = useState(false);
  const [temPermissao, setTemPermissao] = useState(true);

  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => permissao.permissoesLista?.includes('cod_configurar_permissoes'));
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      console.log('Técnico secundário sem permissão para configurar.');
    }
  
  }, []);
  


  useEffect(() => {
    async function fetchPermissoes() {
      try {
        const response = await PermissaoService.listarPermissoes();
        await buscarTodosTecnicos();
        await buscarUsuariosComPermissoesAtivas(page, pageSize, pesquisa);

        if (!response.error) {
          const permissoesRecebidas = Array.isArray(response) ? response : response.data;

          const permissoesOrdenadas = permissoesRecebidas.sort((a, b) =>
            moment(b.dataCadastro).valueOf() - moment(a.dataCadastro).valueOf()
          );

          setAllPermissoes(permissoesOrdenadas || []);
          setTotalDados(response.total || permissoesOrdenadas.length || 0);
        } else {
          setAllPermissoes([]);
        }
      } catch (error) {
        setAllPermissoes([]);
      }
    }
    fetchPermissoes();
  }, []);

  const handleEdit = async (tecnico) => {
    if (!tecnico || !tecnico.usuarioId) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível carregar os dados do técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
      return;
    }

    try {
      let permissoes = tecnico.permissoes;
      if (typeof permissoes === 'string') {
        permissoes = permissoes.split(',').map((perm) => perm.trim());
      }

      if (Array.isArray(permissoes) && permissoes.length > 0) {
        setSelectedKeys(permissoes);
      } else {
        setSelectedKeys([]);
      }

      setSelectedTecnico(tecnico);
      setOpenEditModal(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível carregar as permissões do técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
    }
  };

  const handleAddPermission = async () => {
    if (!selectedTecnico) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Selecione um técnico.',
      });
      setAlertType('error');
      setAlertOpen(true);
      return;
    }

    setEfeitoCarregandoAdicaoTecnicoPermissao(true);
    
    try {
      if (!selectedTecnico.id) {
        throw new Error('ID do técnico não encontrado.');
      }

      const response = await permissoesService.buscarPermissoesTecnico(selectedTecnico.id);

      if (response?.permissoes?.length > 0) {
        setAlertMessage({
          title: 'Atenção!',
          body: 'Este técnico já possui permissões atribuídas. Use a opção "Editar" para alterar as permissões.',
        });
        setAlertType('fail');
        setAlertOpen(true);
        setEfeitoCarregandoAdicaoTecnicoPermissao(false);
        return;
      }

      if (selectedPermissions.length === 0) {
        setAlertMessage({
          title: 'Erro!',
          body: 'Selecione pelo menos uma permissão.',
        });
        setAlertType('error');
        setAlertOpen(true);
        setEfeitoCarregandoAdicaoTecnicoPermissao(false);
        return;
      }

      await permissoesService.adicionarPermissaoUsuario(selectedTecnico.id, selectedPermissions);

      await buscarUsuariosComPermissoesAtivas(page, pageSize, pesquisa);

      setOpenAddPermissionModal(false);
      setSelectedTecnico(null);
      setSelectedPermissions([]);
      setEfeitoCarregandoAdicaoTecnicoPermissao(false);

      setAlertMessage({
        title: 'Sucesso!',
        body: 'Permissões adicionadas com sucesso.',
      });
      setAlertType('success');
      setAlertOpen(true);
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: error.message || 'Não foi possível adicionar as permissões.',
      });
      setAlertType('error');
      setAlertOpen(true);
      setEfeitoCarregandoAdicaoTecnicoPermissao(false);
    }
  };

  const handleSavePermissoes = async () => {
    try {
      setEfeitoCarregandoEdicaoTecnicoPermissao(true);

      const responseEdicao = await permissoesService.atualizarPermissaoUsuario(selectedTecnico.id, selectedKeys);

      if (!responseEdicao.error) {

        buscarUsuariosComPermissoesAtivas(page, pageSize, pesquisa);

        setAlertMessage({
          title: 'Sucesso!',
          body: selectedKeys.length === 0
            ? 'Todas as permissões foram removidas com sucesso.'
            : 'Permissões atualizadas com sucesso.',
        });
        setAlertType('success');
        setAlertOpen(true);
        setOpenEditModal(false);
        setEfeitoCarregandoEdicaoTecnicoPermissao(false);
      } else {
        setAlertMessage({
          title: 'Erro!',
          body: responseEdicao.message
        });
        setAlertType('error');
        setAlertOpen(true);
        setEfeitoCarregandoEdicaoTecnicoPermissao(false);

      }
    } catch (error) {
      setAlertMessage({
        title: 'Erro!',
        body: 'Não foi possível atualizar as permissões.',
      });
      setAlertType('error');
      setAlertOpen(true);
      setEfeitoCarregandoEdicaoTecnicoPermissao(false);
    }
  };

  const buscarUsuariosComPermissoesAtivas = async (page, pageSize, pesquisa) => {
    setCarregandoTable(true);
    try {
      const userInfo = authService.getUserInfo();
      if (!userInfo || !userInfo.usuario) {
        throw new Error('Informações do usuário não disponíveis.');
      }

      const dados = {
        pagina: page ? page : 0,
        limite: pageSize ? pageSize : 8,
        pesquisa: pesquisa ? pesquisa : '',
        idEmpresa: userInfo?.usuario?.id
      };

      const response = await permissoesService.registroUsuariosComPermissoesAtivas(dados);

      if (!response.error) {
        setDadosTecnico(response.data.dados);
        setTotalDados(response.data.total);
        setCarregandoTable(false);
      } else {
        setDadosTecnico([]);
        setCarregandoTable(false);
      }
    } catch (error) {
      setCarregandoTable(false);
      return;
    }
    setCarregandoTable(false);
  };

  const mudarPagina = async (event, newPage) => {
    setPage(newPage);
    await buscarUsuariosComPermissoesAtivas(newPage, pageSize, pesquisa)
  };

  const mudarTamanhoPagina = async (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
    await buscarUsuariosComPermissoesAtivas(0, parseInt(event.target.value, 10), pesquisa)
  };

  const limparPesquisa = () => {
    setPesquisa('');
  };

  const buscarTodosTecnicos = async () => {
    try {
      const userInfo = authService.getUserInfo();

      if (!userInfo || !userInfo.usuario) {
        throw new Error('Informações do usuário não disponíveis.');
      }

      const response = await perfilTecnicoService.buscarTecnicoEmpresa(userInfo.usuario.id);
      if (!response.error) {
        setAllTecnicos(response);
      } else {
        setAllTecnicos([]);
      }
    } catch (error) {
      setAllTecnicos([]);
    }
  };

  const handleViewPermissions = async (tecnico) => {
    setSelectedTecnico(tecnico);
    try {
      let permissoes = tecnico.permissoes;
      if (typeof permissoes === 'string') {
        permissoes = permissoes.split(',').map((perm) => perm.trim());
      }
      const permissoesComNomes = permissoes.map((codigo) => {
        const permissao = allPermissoes.find((perm) => perm.codigoPermissao === codigo);
        return permissao ? permissao.nome : codigo;
      });
      setTecnicoPermissoes(permissoesComNomes);
    } catch (error) {
      setTecnicoPermissoes([]);
    }
    setOpenViewPermissionsDialog(true);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      buscarUsuariosComPermissoesAtivas(0, pageSize, nome);
    }, 700), []
  );

  const valorPesquisa = (nome) => {
    setPesquisa(nome);
    if (nome !== pesquisa) {
      setPesquisa(nome);
      delayPesquisa(nome);
    }
  };

  return (
    <>
 

 <MenuComponent />
      <CustomAlert
        open={alertOpen}
        message={alertMessage}
        type={alertType}
        onClose={() => setAlertOpen(false)}
      />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Configurar Permissões</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => (window.location.href = '/gestor')}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span>{' '}
          {'>'} Configurar Permissões
        </h4>
      </div>

      <div style={{ margin: 30 }}>
        <Grid container spacing={2} className={classes.searchContainer}>
        {temPermissao && (
          <Grid item xs={12} sm={6} md={3}>
            <BlueButton
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              style={{ height: 55 }}
              onClick={() => setOpenAddPermissionModal(true)}
              startIcon={<AddIcon />}
            >
              <span style={{ fontSize: '0.7rem' }}>Cadastrar Permissão</span>
            </BlueButton>
          </Grid>
          )}
          <Grid item xs={12} sm={6} md={5}></Grid>
          {temPermissao && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Pesquisar"
              variant="outlined"
              fullWidth
              value={pesquisa}
              onChange={(event) => valorPesquisa(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {pesquisa ? (
                      <IconButton
                        aria-label="Limpar Pesquisa"
                        onClick={limparPesquisa}
                        edge="end"
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          )}
        </Grid>
      </div>

      <div style={{ margin: 30 }}>
        <Paper className={classes.paper}>
          <Box>
            {temPermissao ? (
              <>
                {carregandoTable ? (
                  <div className={classes.loader}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Grid container spacing={1} alignItems="stretch">
                      {dadosTecnico.length > 0 ? (
                        dadosTecnico.map((tecnico) => (
                          <Grid item key={tecnico.id} xs={12} sm={6} md={3}>
                            <Paper
                              style={{
                                padding: '16px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                minHeight: '100%',
                                boxSizing: 'border-box',
                              }}
                            >
                              <Box padding={1} style={{ flexGrow: 1 }} key={tecnico}>
                                <Typography
                                  component="div"
                                  variant="body2"
                                  style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                                >
                                  <strong>Técnico:</strong> {tecnico.nomeTecnico}
                                </Typography>
                                <Typography
                                  component="div"
                                  variant="body2"
                                  style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                                >
                                  <strong>Email:</strong> {tecnico.loginTecnico}
                                </Typography>
                              </Box>

                              <Grid container spacing={1} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    startIcon={<VisibilityIcon />}
                                    onClick={() => handleViewPermissions(tecnico)}
                                    fullWidth
                                    style={{ height: 55 }}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>Ver Permissões</span>
                                  </BlueButton>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                  <BlueButton
                                    variant="contained"
                                    color="primary"
                                    startIcon={<EditIcon />}
                                    onClick={() => handleEdit(tecnico)}
                                    fullWidth
                                    style={{ height: 55 }}
                                  >
                                    <span style={{ fontSize: '0.7rem' }}>Editar Permissões</span>
                                  </BlueButton>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        ))
                      ) : (
                        <Grid item xs={12}>
                          <Box textAlign="center" p={2}>
                            Nenhum técnico encontrado.
                          </Box>
                        </Grid>
                      )}
                    </Grid>

                    {dadosTecnico.length > 0 && (
                      <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                        <Grid item>
                          <CustomTablePagination
                            onPageChange={mudarPagina}
                            page={page}
                            count={totalDados}
                            quantidadeItens={totalDados}
                            rowsPerPage={pageSize}
                            onRowsPerPageChange={mudarTamanhoPagina}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ) : (
                <Grid container justifyContent="center">
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                    Você não tem permissão para acessar esta página!
                  </h2>
                </Grid>
            )}
          </Box>
        </Paper>
      </div>

      <Dialog
        open={openEditModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenEditModal(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            marginTop: isMobile ? '57px' : '100px',
          },
        }}
      >
        <DialogTitle>
          {selectedTecnico ? `Técnico: ${selectedTecnico.nomeTecnico}` : 'Selecione um técnico'}
          <IconButton
            onClick={() => setOpenEditModal(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Tooltip title="Clique para saber como atribuir permissões" arrow>
            <IconButton
              onClick={() => {
                setOpenInfoPopup(true);
                setInfoMode('editar');
              }}
              style={{ position: 'absolute', right: 50, top: 8 }}
            >
              <InfoIcon style={{ color: '#209BDE' }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.TituloPermissao}>
                Permissões
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.TituloPermissao}>
                Alterar Permissões
              </Typography>
            </Grid>
          </Grid>

          <Transfer
            dataSource={allPermissoes.map((p) => ({
              key: `${p.codigoPermissao}`,
              title: p.nome,
              description: p.descricao,
            }))}
            targetKeys={selectedKeys}
            onChange={(nextTargetKeys) => setSelectedKeys(nextTargetKeys)}
            render={(item) => item.title}
            listStyle={{
              width: '45%',
              height: '300px',
            }}
            showSearch
            filterOption={(inputValue, item) =>
              item.title.toLowerCase().includes(inputValue.toLowerCase())
            }
            locale={{
              itemUnit: 'item',
              itemsUnit: 'itens',
              notFoundContent: 'Nenhum dado encontrado',
              searchPlaceholder: 'Pesquisar permissões',
            }}
            style={{ padding: '10px 0px 10px 10px' }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            onClick={() => setOpenEditModal(false)}
            variant="contained"
            fullWidth
            disabled={efeitoCarregandoEdicaoTecnicoPermissao}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            <span style={{ fontSize: '0.7rem' }}>Voltar</span>
          </Button>
          <BlueButton
            onClick={handleSavePermissoes}
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            startIcon={efeitoCarregandoEdicaoTecnicoPermissao ? <CircularProgress size={20} /> : ''}
            disabled={efeitoCarregandoEdicaoTecnicoPermissao}>
            <span style={{ fontSize: '0.7rem' }}>{efeitoCarregandoEdicaoTecnicoPermissao ? ' Salvando...' : ' Salvar'}</span>
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddPermissionModal}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenAddPermissionModal(false)
          }
        }}
        aria-labelledby="customized-dialog-title"
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5,
            marginTop: isMobile ? '57px' : '85px',
          },
        }}
      >
        <DialogTitle>
          Cadastrar Permissões
          <IconButton
            onClick={() => setOpenAddPermissionModal(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Tooltip title="Clique para saber como atribuir permissões" arrow>
            <IconButton
              onClick={() => {
                setOpenInfoPopup(true);
                setInfoMode('cadastrar');
              }}
              style={{ position: 'absolute', right: 50, top: 8 }}
            >
              <InfoIcon style={{ color: '#209BDE' }} />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent dividers>

          <Box mb={3}>
            <Select
              options={allTecnicos.map((tecnico) => ({
                value: tecnico.idTecnico,
                label: tecnico.nomeTecnico,
              }))}
              onChange={(option) => setSelectedTecnico({ id: option.value, nome: option.label })}
              placeholder="Selecione um técnico"
              styles={{
                container: (base) => ({
                  ...base,
                  width: '97%',
                  marginRight: '5%',
                  padding: '0px 0px 0px 10px'
                }),
              }}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.TituloPermissao}>
                Selecionar Permissão
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.TituloPermissao}>
                Permissão Selecionadas
              </Typography>
            </Grid>
          </Grid>
          <Box>
            <Transfer
              dataSource={allPermissoes.map((p) => ({
                key: `${p.codigoPermissao}`,
                title: p.nome,
                description: p.descricao,
              }))}
              targetKeys={selectedPermissions}
              onChange={(nextTargetKeys) => setSelectedPermissions(nextTargetKeys)}
              render={(item) => item.title}
              listStyle={{
                width: '45%',
                height: '300px',
              }}
              showSearch
              filterOption={(inputValue, item) =>
                item.title.toLowerCase().includes(inputValue.toLowerCase())
              }
              locale={{
                itemUnit: 'item',
                itemsUnit: 'itens',
                notFoundContent: 'Nenhum dado encontrado',
                searchPlaceholder: 'Pesquisar permissões',
              }}
              style={{ padding: '10px 0px 10px 10px' }}
            />
          </Box>

        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={() => setOpenAddPermissionModal(false)}
            disabled={efeitoCarregandoAdicaoTecnicoPermissao}
          >
            <span style={{ fontSize: '0.7rem' }}>Voltar</span>
          </Button>
          <BlueButton
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
            onClick={handleAddPermission}
            disabled={!selectedTecnico || selectedPermissions.length === 0 || efeitoCarregandoAdicaoTecnicoPermissao}
            startIcon={efeitoCarregandoAdicaoTecnicoPermissao ? <CircularProgress size={20} /> : ''}
          >
            <span style={{ fontSize: '0.7rem' }}>{efeitoCarregandoAdicaoTecnicoPermissao ? ' Cadastrando...' : ' Cadastrar'}</span>
          </BlueButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openInfoPopup}
        aria-labelledby="info-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenInfoPopup(false)
          }
        }}
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5,
            marginTop: isMobile ? '57px' : '0px',
          },
        }}
      >
        <DialogTitle>
          {infoMode === 'editar' ? 'Como Editar Permissões' : 'Como Atribuir Permissões'}
          <IconButton
            onClick={() => setOpenInfoPopup(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {infoMode === 'editar' ? (
            <>
              <Typography variant="body1" style={{ marginBottom: 10 }}>
                Para editar permissões a um técnico:
              </Typography>
              <ul>
                <li>Escolha as permissões disponíveis na lista Permitir</li>
                <li>Selecione as permissões clicando encima e para permitir clique <ChevronRightIcon style={{ fontSize: '14px', color: '#4096ff' }} /></li>
                <li>Use o botão Salvar para aplicar as permissões selecionadas</li>
                <li>Caso o técnico já possua permissões, utilize a opção Editar</li>
              </ul>
            </>
          ) : (
            <>
              <Typography variant="body1">
                Para atribuir permissões a um técnico:
              </Typography>
              <ul>
                <li>Selecione o técnico no campo de seleção</li>
                <li>Escolha as permissões disponíveis na lista Permitir</li>
                <li>Selecione as permissões clicando encima e para permitir clique <ChevronRightIcon style={{ fontSize: '14px', color: '#4096ff' }} /></li>
                <li>Botão Salvar disponivel depois de selecionar o técnico e selecionar permissões</li>
                <li>Use o botão Salvar para aplicar as permissões selecionadas</li>
                <li>Caso o técnico já possua permissões, utilize a opção Editar</li>
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            style={{ width: '40%', height: 55 }}
            onClick={() => setOpenInfoPopup(false)}
          >
            <span style={{ fontSize: '0.7rem' }}>Voltar</span>
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openViewPermissionsDialog}
        aria-labelledby="info-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenViewPermissionsDialog(false)
          }
        }}
        fullWidth
        PaperProps={{
          style: {
            width: isMobile ? '95%' : '50%',
            margin: 5,
            marginTop: isMobile ? '57px' : '0px',
          },
        }}
      >
        <DialogTitle>
          Permissões de {selectedTecnico?.nomeTecnico}
          <IconButton
            onClick={() => setOpenViewPermissionsDialog(false)}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {Array.isArray(tecnicoPermissoes) && tecnicoPermissoes.length > 0 ? (
            <Grid container spacing={2}>
              {tecnicoPermissoes.map((permissao, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {getIconForPermission(permissao)} 
                    <span style={{ marginLeft: 8 }}>{permissao}</span>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>Nenhuma permissão atribuída.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenViewPermissionsDialog(false)}
            variant="contained"
            fullWidth
            style={{ width: '40%', height: 55 }}
          >
            <span style={{ fontSize: '0.7rem' }}>Fechar</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
