import config from '../../config';
import authService from '../auth/auth-service';

const _configAPITecnico = config.configAPITecnico();

class PerfilTecnicoService {

  async listarTecnicos(areaAtendimento, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/listar?AreaAtendimentoId=${areaAtendimento}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.nome}&Status=${dados.status}`, {
        method: 'GET',
        headers: _headerToken,
      },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) return data;

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async adicionarTecnico(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico`,
        {
          method: 'POST',
          headers: _headerToken,
          body: JSON.stringify(dados),
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os dados',
      };
    }
  }

  async editarTecnico(idTecnico, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/${idTecnico}`,
        {
          method: 'PUT',
          headers: _headerToken,
          body: JSON.stringify(dados),
        },
      );
      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200)
        return {
          error: false,
          result: data,
        };

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao editar o produto.',
      };
    }
  }

  async desativarTecnico(idTecnico) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/delete/${idTecnico}`, {
        method: 'PATCH',
        headers: _headerToken,
      });

      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        if (response.status === 200) {
          return {
            error: false,
            result: null,
          };
        } else {
          return {
            error: true,
            message: 'JSON inválido.',
          };
        }
      }

      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao deletar um produto.',
      };
    }
  }

  async inativarTecnico(idTecnico) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/SolicitacaoCadastroTecnico/ativarInativar/${idTecnico}`, {
        method: 'PATCH',
        headers: _headerToken,
      });

      let data;
      try {
        data = await response.json();
      } catch (jsonError) {
        if (response.status === 200) {
          return {
            error: false,
            result: null,
          };
        } else {
          return {
            error: true,
            message: 'JSON inválido.',
          };
        }
      }

      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage,
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao deletar um produto.',
      };
    }
  }

  async cadastrarTecnicoOS(tecnicoOS) {
    try {

      const response = await fetch(`${_configAPITecnico.URI_API}/TecnicoOS`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userInfo')).token}`,
          'Accept': '*/*'
        },
        body: JSON.stringify({
          osNumero: tecnicoOS.osNumero,
          tecnicoId: tecnicoOS.tecnicoId

        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        return { error: true, message: errorData.mensagem || 'Erro ao enviar dados para a API' };
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return { error: true, message: 'Erro ao cadastrar técnico na OS' };
    }
  }

  async buscarTecnicoPorOS(OSNumero) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(
        `${_configAPITecnico.URI_API}/TecnicoOS/buscarTecnicoOS?OSNumero=${OSNumero}`,
        {
          method: 'GET',
          headers: _headerToken,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return { error: true, message: errorData.mensagem || 'Erro ao buscar técnico pela OS' };
      }

      return await response.json();
    } catch (error) {
      return { error: true, message: 'Erro ao buscar técnico pela OS' };
    }
  }

  async buscarTecnicoEmpresa(EmpresaID) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(
        `${_configAPITecnico.URI_API}/Usuario/empresa/${EmpresaID}/tecnicos`,
        {
          method: 'GET',
          headers: _headerToken,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return { error: true, message: errorData.mensagem || 'Erro ao buscar técnico pelo ID' };
      }

      return await response.json();
    } catch (error) {
      return { error: true, message: 'Erro ao buscar técnico pelo ID' };
    }
  }

}

export default new PerfilTecnicoService();
