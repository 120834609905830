import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class DocumentosService {
  async listadocumentos(pagina = 0, limite = 8, pesquisa = '') {
    try {
      const url = `${_configAPITecnico.URI_API}/ManualArquivos?Pagina=${pagina}&Limite=${limite}&Pesquisa=${encodeURIComponent(pesquisa)}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: authService.headerAPITecnico(),
      });
      if (response.status === 200) {
        return await response.json();
      } else {
        return { error: true };
      }
    } catch (error) {
      return { error: true };
    }
  }

  async arquivodocumentos(id,titulo) {
    try {
      const response = await fetch(`${_configAPITecnico.URI_API}/ManualArquivos/download/${id}`, {
        method: 'GET',
        headers: authService.headerAPITecnico(),
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data && data.fileContents) {
          const byteCharacters = atob(data.fileContents);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
          }
          const blob = new Blob(byteArrays, { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${titulo}.pdf`; 
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          return { error: true };
        }
      } else {
        return { error: true };
      }
    } catch (error) {
      return { error: true };
    }
  }
}

export default new DocumentosService();

 