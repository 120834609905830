import {
    Box,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    useMediaQuery, useTheme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import perfilService from '../Services/Perfil/perfil-service';
import authService from '../Services/auth/auth-service';
import CustomAlert from '../components/alert/CustomAlert';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component';
import FilesExportFilterProducts from '../servicos/components/filesExportFilterProducts';

const useStyles = makeStyles((theme) => ({
    tituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
        marginBottom: '5px',
    },
    subtituloStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#7F7F7F',
        fontSize: '16px',
        fontWeight: 'inherit',
        textAlign: 'start',
        padding: '0 0px',
        marginTop: '0px',
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 20px 15px',
        borderRadius: 10,
        width: '100%',
    },
    valorAlert: {
        fontSize: '14px',
        fontWeight: 'bold',
        padding: theme.spacing(1),
    },
    searchFieldContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
    },
    mensagemStyle: {
        fontFamily: 'Mont, sans-serif',
        color: '#209BDE',
        fontSize: '22px',
        textAlign: 'start',
        padding: '0 0px',
    },
}));

export default function PerfilOuro() {
    const classes = useStyles();
    const [pageSize, setPageSize] = useState(8);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [perfilInfo, setPerfilInfo] = useState({});
    const mobile = useMediaQuery(useTheme().breakpoints.down(800));
    const [, setLoadDados] = useState(false);
    const [abrirAlert, setAbrirAlert] = useState(false);
    const [mensagemAlert, setMensagemAlert] = useState('');
    const [tipoAlert, setTipoAlert] = useState('error');
    const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
    const [searchTerm, setSearchTerm] = useState('');

    const [temPermissao, setTemPermissao] = useState(true);
    useEffect(() => {
        const usuarioInfo = authService.getUserInfo().usuario;
        const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
        
        const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
        const empresa = usuarioInfo.empresa; 
        const tecnico = usuarioInfo.tecnico;
        let temPermissao = true; 
      
        if (empresa || tecnico) {
          temPermissao = permissaoAgendaGlobal?.some(permissao => permissao.permissoesLista?.includes('cod_financeiro'));
        } else {
          temPermissao = true;
        }
      
        if (!temPermissao) {
          setTemPermissao(false);
        } else {
          setTemPermissao(true);
        }
        if (tecnicoPrestador && !temPermissao) {
          console.log('Técnico secundário sem permissão para configurar.');
        }
      
      }, []);

    useEffect(() => {
        perfil();
    }, [mobile]);

    useEffect(() => {
        setPage(0);
    }, [searchTerm]);

    const perfil = async () => {
        try {
            setLoadDados(true);
            const info = await perfilService.getInformacoesPerfilOuro();
            if (info) {
                setPerfilInfo({
                    nome: info.perfil.nome,
                    valoresServico: info.perfil.tabelaDePrecosCodigo || '',
                    categoria: info.perfil.tabelaDePrecosDescricao || '',
                    tabelaDePrecos: info.tabelaDePrecos || [],
                });
            }
        } catch (error) {
            setMensagemAlert({ title: 'Erro!', body: 'Erro ao buscar informações de perfil:', error });
            setTipoAlert('error');
            setAbrirAlert(true);
        } finally {
            setLoading(false);
            setLoadDados(false);
        }
    };

    function formatarDescricao(descricao) {
        const dicionario = {
            'DESINSTALACAO': 'Desinstalação',
            'REINSTALACAO': 'Reinstalação',
            'INSTALACAO': 'Instalação',
            'MANUTENCAO': 'Manutenção',
            'TELEMETRIA': 'Telemetria',
            'SERVICO': 'Serviço',
            'KIT': 'Kit',
            'NO SHOW': 'No Show',
            'DESBLOQUEIO': 'Desbloqueio',
            'EMERGENCIAL': 'Emergencial',
            'OMNILINK': 'Omnilink',
            'Reativacao': 'Reativação',
        };

        let descricaoFormatada = descricao.toLowerCase();
        const palavras = descricaoFormatada.split(' ');
        const palavrasFormatadas = palavras.map(palavra => {
            const palavraUpper = palavra.toUpperCase();
            return dicionario[palavraUpper] || palavra.charAt(0).toUpperCase() + palavra.slice(1);
        });

        return palavrasFormatadas.join(' ');
    }

    const dadosFiltrados = perfilInfo.tabelaDePrecos
        ? perfilInfo.tabelaDePrecos.filter(item =>
            formatarDescricao(item.produto).toLowerCase().includes(searchTerm.toLowerCase()) ||
            formatarDescricao(item.descricao).toLowerCase().includes(searchTerm.toLowerCase())
        )
        : [];

    const dadosPaginados = dadosFiltrados.slice(page * pageSize, page * pageSize + pageSize);

    const mudarTamanhoPagina = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const mudarPagina = (event, newPage) => {
        setPage(newPage);
    };

    const handleProductSearch = (term) => {
        setSearchTerm(term);
    };

    const dataExportacao = dadosFiltrados.map(item => ({
        Produto: item.produto,
        Descrição: formatarDescricao(item.descricao),
        Valor: `R$ ${item.valor.toFixed(2)}`,
    }));

    return (
        <>
            <MenuComponent />
            <CustomAlert
                open={abrirAlert}
                message={mensagemAlert}
                type={tipoAlert}
                onClose={() => setAbrirAlert(false)}
            />
              {temPermissao ? (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Financeiro</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => window.location.href = '/agenda'}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Financeiro
                    </h4>

                    <Grid container spacing={2} className={classes.searchFieldContainer}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                label="Pesquisar Serviço"
                                variant="outlined"
                                fullWidth
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {searchTerm ? (
                                                <IconButton
                                                    onClick={() => {
                                                        setSearchTerm('');
                                                        handleProductSearch('');
                                                    }}
                                                    size="small"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            ) : (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            )}
                                        </>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <FilesExportFilterProducts
                                fileName={'Tabela de Valores'}
                                data={dataExportacao}
                            />
                        </Grid>
                    </Grid>

                    {loading ? (
                        <Paper className={classes.paper}>
                            <div className={classes.loadingContainer}>
                                <CircularProgress />
                            </div>
                        </Paper>
                    ) : (
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid container style={{ padding: '16px 32px', alignItems: 'center' }}>
                                    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '16px 0' }}>
                                        <strong>Razão Social:</strong> {perfilInfo.nome}
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '16px 0' }}>
                                        <strong>Categoria:</strong> {perfilInfo.categoria}
                                    </Grid>
                                    <Grid item xs={12} md={4} style={{ textAlign: 'center', padding: '16px 0' }}>
                                        <strong>Valores Serviço:</strong> {perfilInfo.valoresServico}
                                    </Grid>
                                </Grid>
                                {dadosPaginados.length > 0 ? (
                                    <Grid container spacing={1} alignItems="stretch">
                                        {dadosPaginados.map((item, index) => (
                                            <Grid item key={index} xs={12} md={3} style={{ display: 'flex' }}>
                                                <Paper
                                                    style={{
                                                        padding: '16px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        minHeight: '130px',
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    <Grid container spacing={1} alignItems="flex-start">
                                                        <Grid item xs={12}>
                                                            <Box fontWeight="fontWeightBold">{item.produto}</Box>
                                                            <Box>{formatarDescricao(item.descricao)}</Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={1} alignItems="flex-start">
                                                        <Grid item xs={12} style={{ textAlign: 'right', marginTop: '8px' }}>
                                                            <Alert severity="info" className={classes.valorAlert}>
                                                                R$ {item.valor.toFixed(2)}
                                                            </Alert>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="center" alignItems="center" style={{ margin: 10 }}>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Nenhum registro encontrado
                                        </Typography>
                                    </Grid>
                                )}

                                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                                    <Grid item>
                                        <CustomTablePagination
                                            count={dadosFiltrados.length}
                                            page={page}
                                            rowsPerPage={pageSize}
                                            onPageChange={mudarPagina}
                                            quantidadeItens={dadosFiltrados.length}
                                            onRowsPerPageChange={mudarTamanhoPagina}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}
                </div>
            ) : (
                <div style={{ margin: 30 }}>
                    <h3 className={classes.tituloStyle}>Financeiro</h3>
                    <h4 className={classes.subtituloStyle}>
                        <span
                            onClick={() => window.location.href = '/agenda'}
                            className={classes.linkStyle}
                            style={{ cursor: 'pointer' }}
                        >
                            Home
                        </span> {'>'} Financeiro
                    </h4>
                    <Paper className={classes.paper}>
                        <Grid container justifyContent="center">
                            <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
                        </Grid>
                    </Paper>
                </div>
            )}
        </>
    );
}
