import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, Paper, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import MenuComponent from '../components/menu/menu-component';
import CustomTablePagination from '../components/pagination/pagination-component';
import authService from '../Services/auth/auth-service';
import notaFiscalService from '../Services/NotaFiscal/nota-fiscal-service';
import usuarioService from '../Services/Usuario/usuario-service';
import ContestacaoNotaFiscal from './components/contestacao-nota-fiscal';
import DetalheNotaFiscal from './components/detalhe-nota-fiscal';

// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ReplayIcon from '@mui/icons-material/Replay';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
    marginBottom: 20
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
  },
  item: {
    marginBottom: 30,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}));

export default function NotasFiscaisPage({ microSigaID, handleCloseNFGestorTecnico, nomeTecnico }) {
  const classes = useStyles();
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const usuarioGestor = authService?.getUserInfo()?.usuario?.gestor == true ? true : false;
  const tecnicoPrestador = authService?.getUserInfo()?.usuario?.tecnico;
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [notasFiltradas, setNotasFiltradas] = useState([]);
  const [nf, setNF] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [ContestacaoNF, setContestacaoNF] = useState(null);
  const [permissaoTCL, setPermissaoTCL] = useState(0);
  const [tableKey, setTableKey] = useState(0);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const [totalDados, setTotalDados] = useState(0);
  const [carregandoTable, setCarregandoTable] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [page, setPage] = useState(0);
  const [openDialogConfirmar, setopenDialogConfirmar] = useState(false);
  let [pageSize, setPageSize] = useState(8);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [loading, setLoading] = useState(true);
  const [carregandoBotaoLimpar, setCarregandoBotaoLimpar] = useState(false);
  const [carregandoBotaoPesquisar, setCarregandoBotaoPesquisar] = useState(false);
  const [notaSelecionada, setNotaSelecionada] = useState(null);
  const [loadingRastrear, setLoadingRastrear] = useState(false);
  const [, setNotasItens] = useState([]);

  const buscarUsuarioEstoqueAtivo = async () => {
    const response = await usuarioService.usuarioEstoqueAtivo(authService?.getUserInfo()?.usuario?.id);
    if (response.error) {
      return;
    } else {
      setUsuarioEstoqueAtivo(response);
    }
  }

  const handleClick = (nota) => {
    setNF(nota);
    setShowDetails(!showDetails);
  };

  const handleClickContestacaoNF = (nota) => {
    setContestacaoNF(nota);
    setShowDetails(!showDetails);
  };

  const handleClose = () => {
    setNF(null);
    valorPesquisa('');
    setTableKey(tableKey + 1);
    setContestacaoNF(null);
    setShowDetails(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      notasFiscais();
      buscarUsuarioEstoqueAtivo();
      setPermissaoTCL(authService?.getUserInfo()?.usuario?.classificacaoPrestador);
      if (mobile) {
        setTableKey(tableKey + 1);
      } else {
        setTableKey(tableKey + 1);
      }
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  const statusOptions = [
    { value: '', label: 'Todos' },
    { value: 'em_separacao', label: 'Em Separação' },
    { value: 'em_transito', label: 'Em Trânsito' },
    { value: 'recebido', label: 'Recebido' },
    { value: 'recusado', label: 'Recusado' },
    { value: 'preparando_envio_para_zatix', label: 'Preparando Envio para Zatix' },
    { value: 'enviado_para_zatix', label: 'Enviado para Zatix' },
    { value: 'em_transporte_para_zatix', label: 'Em Transporte para Zatix' },
    { value: 'reversa', label: 'Reversa' },
    { value: 'recusado_por_zatix', label: 'Recusado por Zatix' },
    { value: 'nota_cancelada', label: 'Nota Cancelada' },
    { value: 'recebido_parcialmente', label: 'Recebido Parcialmente' },
    { value: 'transporte_para_area_atendimento', label: 'Em Transporte para Área de Atendimento' },
  ];

  const customStyles = {
    control: (provided,) => ({
      ...provided,
      height: '55px',
      minHeight: '55px',
      borderRadius: '4px',
      borderColor: '#c4c4c4',
      boxShadow: 'none',
      backgroundColor: '#EFF2F8',
      '&:hover': {
        borderColor: '#209BDE',
      },
    }),

    valueContainer: (provided,) => ({
      ...provided,
      height: '55px',
      padding: '0 6px',
    }),
    input: (provided,) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorsContainer: (provided,) => ({
      ...provided,
      height: '55px',
    }),
    placeholder: (provided,) => ({
      ...provided,
      color: '#000',
    }),
    singleValue: (provided,) => ({
      ...provided,
      color: '#000',
    }),
  };

  const receberNotaTotal = async () => {
    if (!notaSelecionada) return;

    setLoadingRastrear(true);

    const result = await notaFiscalService.rastrearNotaFiscal(notaSelecionada);

    if (result.success) {
      setNotasFiltradas((prevNotas) =>
        prevNotas.map((nota) =>
          nota.id === notaSelecionada
            ? { ...nota, Descricao: 'Recebido' }
            : nota
        )
      );
      setMensagemAlert({ title: 'Sucesso!', body: 'Status atualizado para Recebido.' });
      setTipoAlert('success');
    } else {
      console.error(result.message);
      setMensagemAlert({ title: 'Erro!', body: result.message });
      setTipoAlert('error');
    }

    setAbrirAlert(true);
    setopenDialogConfirmar(false);
    setLoadingRastrear(false);
  };

  const notasFiscais = async (dataInicial, dataFinal, page, pageSize, pesquisa, status) => {
    setCarregandoTable(true);

    if (dataInicial && dataFinal && moment(dataInicial).isAfter(dataFinal)) {
      setMensagemAlert({ title: 'Atenção!', body: 'Data inicial não pode ser maior que a data final!' });
      setTipoAlert('fail');
      setAbrirAlert(true);
      setStartDate('');
      setEndDate('');

      setCarregandoTable(false);
      return;
    }

    const dados = {
      dataInicial: dataInicial ? dataInicial : '',
      dataFinal: dataFinal ? dataFinal : '',
      pagina: page ? page : 0,
      limite: pageSize ? pageSize : 8,
      nome: pesquisa ? pesquisa : '',
      status: status ? status : '',
    }

    let notasResult = await notaFiscalService.Notas(dados, microSigaID);

    if (notasResult.dados) {
      let _notasItens = [];
      let dadosNF = notasResult.dados

      dadosNF.map((nt) => {
        _notasItens.push({
          id: nt.id,
          cnpj: nt.cnpj,
          numero: nt.numero,
          serie: nt.serie,
          dataEmissao: nt.dataEmissao || '',
          TipoNota: nt.tipoNota,
          DataSistema: nt.dataSistema,
          Descricao: nt.descricao,
          idStatus: nt.idStatus,
          chave: nt.chave,
          transportadoraCodigo: nt.transportadoraCodigo,
          dataPrevisaoEntrega: nt.dataPrevisaoEntrega ? new Date(nt.dataPrevisaoEntrega) : null,
          dataUltimaAtualizacaoTransportadora: nt.dataUltimaAtualizacaoTransportadora
            ? new Date(nt.dataUltimaAtualizacaoTransportadora) : null,
          dataEntrega: nt.dataEntrega ? new Date(nt.dataEntrega) : null
        });
      });
      setTotalDados(notasResult.total);
      setNotasItens(_notasItens);
      setNotasFiltradas(_notasItens);
      setCarregandoTable(false);
    }
    setCarregandoTable(false);
  };

  const handlePesquisar = async () => {
    setPageSize(8);
    setPage(0);
    if (!startDate && !endDate && !selectedStatus) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Por favor, selecione as datas de início e fim antes de pesquisar.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(startDate).isAfter(endDate)) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data final.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    if (moment(startDate).isAfter(moment())) {
      setMensagemAlert({
        title: 'Atenção!',
        body: 'Data inicial não pode ser maior que a data atual.',
      });
      setTipoAlert('fail');
      setAbrirAlert(true);
      return;
    }

    setCarregandoBotaoPesquisar(true);
    setCarregandoTable(true);
    await notasFiscais(startDate, endDate, 0, 8, pesquisa, selectedStatus?.value);
    setCarregandoTable(false);
    setCarregandoBotaoPesquisar(false);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0);
      notasFiscais(startDate, endDate, 0, pageSize, nome);
    }, 700), []
  );

  const valorPesquisa = (nome) => {
    if (nome !== pesquisa) {
      setPesquisa(nome);
      delayPesquisa(nome);
    }
  };

  const limparPesquisa = () => {
    setPesquisa('');
    notasFiscais(startDate, endDate, 0, pageSize, '');
  };

  const handleLimpar = async () => {
    setCarregandoBotaoLimpar(true);

    setStartDate('');
    setEndDate('');
    setNotasItens([]);
    setNotasFiltradas([]);
    setSelectedStatus(null);
    setPesquisa('');
    setPage(0);
    setPageSize(8);

    await notasFiscais('', '', 0, 8, '', null);

    setCarregandoBotaoLimpar(false);
  };

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    notasFiscais(startDate, endDate, newPage, pageSize, pesquisa, selectedStatus?.value || '');
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    notasFiscais(startDate, endDate, 0, newPageSize, pesquisa, selectedStatus?.value || '');
  };

  const abrirModalReceberNota = (idNota) => {
    setNotaSelecionada(idNota);
    setopenDialogConfirmar(true);
  };

  const styleStatus = (status) => {
    const commonStyle = {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      width: '100%',
      height: 44,
      marginTop: '10px',
      borderRadius: 15,
    };

    const iconStyle = {
      marginRight: '8px',
      fontSize: '20px',
    };

    switch (status) {
      case 'Em Separação':
        return (
          <div style={{ ...commonStyle, background: 'rgb(246 100 176)' }}>
            <HourglassEmptyIcon style={iconStyle} />
            Em Separação
          </div>
        );
      case 'Em Trânsito':
        return (
          <div style={{ ...commonStyle, background: 'rgb(243 108 33)' }}>
            <LocalShippingIcon style={iconStyle} />
            Em Trânsito
          </div>
        );
      case 'Em transporte para Área de Atendimento':
        return (
          <div style={{ ...commonStyle, background: '#42A5F5' }}>
            <LocationOnIcon style={iconStyle} />
            Em transporte para Área de Atendimento
          </div>
        );
      case 'Recebido':
        return (
          <div style={{ ...commonStyle, background: '#4CAF50' }}>
            <CheckCircleIcon style={iconStyle} />
            Recebido
          </div>
        );
      case 'Recusado':
        return (
          <div style={{ ...commonStyle, background: '#E57373' }}>
            <CancelIcon style={iconStyle} />
            Recusado
          </div>
        );
      case 'Preparando Envio para Zatix':
        return (
          <div style={{ ...commonStyle, background: '#FFEE58' }}>
            <BuildIcon style={iconStyle} />
            Preparando Envio para Zatix
          </div>
        );
      case 'Enviado para Zatix':
        return (
          <div style={{ ...commonStyle, background: '#1976D2' }}>
            <LocalShippingIcon style={iconStyle} />
            Enviado para Zatix
          </div>
        );
      case 'Em transporte para Zatix':
        return (
          <div style={{ ...commonStyle, background: '#1E88E5' }}>
            <FlightTakeoffIcon style={iconStyle} />
            Em transporte para Zatix
          </div>
        );
      case 'Reversa':
        return (
          <div style={{ ...commonStyle, background: '#9575CD' }}>
            <ReplayIcon style={iconStyle} />
            Reversa
          </div>
        );
      case 'Recusado por Zatix':
        return (
          <div style={{ ...commonStyle, background: '#F44336' }}>
            <HighlightOffIcon style={iconStyle} />
            Recusado por Omnilink
          </div>
        );
      case 'Nota Cancelada':
        return (
          <div style={{ ...commonStyle, background: '#D32F2F' }}>
            <CancelIcon style={iconStyle} />
            Nota Cancelada
          </div>
        );
      case 'Recebido Parcialmente':
        return (
          <div style={{ ...commonStyle, background: '#FFB74D' }}>
            <HourglassTopIcon style={iconStyle} />
            Recebido Parcialmente
          </div>
        );
      default:
        return (
          <div style={{ ...commonStyle, background: '#B0BEC5' }}>
            <HelpOutlineIcon style={iconStyle} />
            Status desconhecido
          </div>
        );
    }
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      {permissaoTCL !== 2 && tecnicoPrestador == null || tecnicoPrestador == false ? (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Notas Fiscais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => (window.location.href = '/agenda')}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span>
            {' > '}
            Notas Fiscais
            {authService?.getUserInfo()?.usuario?.gestor === true &&
              nomeTecnico &&
              nomeTecnico.trim() !== '' ? (
              <>
                {' > '}
                {nomeTecnico}
              </>
            ) : null}
          </h4>

          {!showDetails && (
            <>
              {authService?.getUserInfo()?.usuario?.gestor === true &&
                nomeTecnico &&
                nomeTecnico.trim() !== '' ? (
                <Grid item xs={3} sm={1} md={1} style={{ marginBottom: 20 }}>
                  <Button
                    onClick={handleCloseNFGestorTecnico}
                    style={{ color: '#209BDE' }}
                    startIcon={<ArrowBackIosIcon />}
                  >
                    Voltar
                  </Button>
                </Grid>
              ) : null}

              <div style={{ marginBottom: 20 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id="start-date"
                        label="Data inicial"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        id="end-date"
                        label="Data final"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select
                      options={statusOptions}
                      placeholder="Selecione o Status"
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      isClearable
                      styles={customStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <BlueButton
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handlePesquisar}
                      fullWidth
                      disabled={carregandoBotaoPesquisar}
                      startIcon={carregandoBotaoPesquisar ? <CircularProgress size={20} /> : <SearchIcon />}
                      style={{ height: 55 }}
                    >
                      <span>{carregandoBotaoPesquisar ? ' BUSCANDO...' : ' BUSCAR'}</span>
                    </BlueButton>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <BlueButton
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleLimpar}
                      fullWidth
                      disabled={carregandoBotaoLimpar}
                      startIcon={carregandoBotaoLimpar ? <CircularProgress size={20} /> : <ClearIcon />}
                      style={{ height: 55 }}
                    >
                      <span>{carregandoBotaoLimpar ? ' LIMPANDO...' : ' LIMPAR'}</span>
                    </BlueButton>
                  </Grid>
                </Grid>
              </div>

              <Dialog
                aria-labelledby="customized-dialog-title"
                open={openDialogConfirmar}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    setopenDialogConfirmar(false);
                  }
                }}
                style={{ marginTop: 45, marginBottom: 10 }}
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Confirmar recebimento
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={() => setopenDialogConfirmar(false)}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>

                <DialogContent dividers>
                  <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
                    Ao clicar em Receber  nota fiscal, os itens serão adicionados ao seu estoque e esta ação não poderá ser desfeita. Tem certeza que deseja continuar?
                  </Typography>
                </DialogContent>

                <DialogActions style={{ justifyContent: 'space-around' }}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%' }}
                    onClick={() => setopenDialogConfirmar(false)}
                  >
                    Fechar
                  </Button>

                  <BlueButton
                    variant="contained"
                    fullWidth
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%' }}
                    onClick={receberNotaTotal}
                    disabled={loadingRastrear}
                  >
                    {loadingRastrear ? (
                      <CircularProgress size={24} style={{ color: 'white' }} />
                    ) : (
                      'Confirmar'
                    )}
                  </BlueButton>
                </DialogActions>
              </Dialog>

              <Paper className={classes.paper} >
                <Grid container spacing={1} direction="column">
                  <Grid container justifyContent="flex-end" item xs={12} sm={12} style={{ marginBottom: 15 }}>
                    <TextField
                      label="Pesquisar"
                      variant="outlined"
                      value={pesquisa}
                      onChange={(event) => valorPesquisa(event.target.value)}
                      style={{ width: mobile ? '100%' : '40%' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {pesquisa ? (
                              <IconButton onClick={limparPesquisa}>
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                {carregandoTable ? (
                  <Grid container justifyContent="center" alignItems="center" style={{ height: '400px' }}>
                    <CircularProgress />
                  </Grid>
                ) : notasFiltradas.length === 0 ? (
                  <Grid container justifyContent="center" alignItems="center" style={{ height: '100px' }}>
                    <Typography variant="subtitle1" color="textSecondary">
                      Não há notas fiscais.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={1} alignItems="stretch">
                    {notasFiltradas.map((rowData, index) => (
                      <Grid item key={index} xs={12} md={3} style={{ width: '100%', maxWidth: '100%' }}>
                        <Paper
                          style={{
                            padding: '16px',
                            flex: '1 0 auto',
                            height: '100%',
                            boxSizing: 'border-box',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Box padding={1} style={{ flexGrow: 1 }}>
                            <Typography component="div" variant="body2"><strong>Número: </strong>{rowData.numero}</Typography>
                            <Typography component="div" variant="body2"><strong>Data de Emissão: </strong>{rowData.dataEmissao}</Typography>
                          </Box>

                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <Typography component="div" variant="body2">{styleStatus(rowData.Descricao)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <BlueButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AssignmentIcon />}
                                onClick={() => handleClick(rowData)}
                                style={{
                                  height: 55,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                              >
                                <span style={{ fontSize: '0.7rem' }}>Detalhes</span>
                              </BlueButton>
                            </Grid>
                            {(usuarioEstoqueAtivo || usuarioGestor) && (
                              <Grid item xs={12}>
                                <BlueButton
                                  fullWidth
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  startIcon={<AssignmentIcon />}
                                  onClick={() => handleClickContestacaoNF(rowData)}
                                  style={{
                                    height: 55,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <span style={{ fontSize: '0.7rem' }}>Contestações</span>
                                </BlueButton>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <BlueButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AssignmentIcon />}
                                style={{
                                  height: 55,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                }}
                                onClick={() => abrirModalReceberNota(rowData.id)}
                                disabled={rowData.Descricao !== 'Em Trânsito' && rowData.Descricao !== 'Em Separação'}
                              >
                                <span style={{ fontSize: '0.7rem' }}>
                                  {
                                    rowData.Descricao === 'Reversa'
                                      ? 'Reversa'
                                      : rowData.Descricao === 'Recebido Parcialmente'
                                        ? 'Recebido Parcialmente'
                                        : rowData.Descricao === 'Recebido'
                                          ? 'Nota Recebida'
                                          : rowData.Descricao === 'Recusado por Zatix'
                                            ? 'Recusado por Omnilink'
                                            : 'Receber nota'
                                  }
                                </span>
                              </BlueButton>
                            </Grid>
                          </Grid>

                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {notasFiltradas.length > 0 && (
                  <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                    <Grid item>
                      <CustomTablePagination
                        count={totalDados}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={mudarPagina}
                        quantidadeItens={totalDados}
                        onRowsPerPageChange={mudarTamanhoPagina}
                      />
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </>
          )}

        </div>
      ) : (
        <div style={{ margin: 30 }}>
          <h3 className={classes.tituloStyle}>Notas Fiscais</h3>
          <h4 className={classes.subtituloStyle}>
            <span
              onClick={() => window.location.href = '/agenda'}
              className={classes.linkStyle}
              style={{ cursor: 'pointer' }}
            >
              Home
            </span>
            {' > '}
            Notas Fiscais
            {authService?.getUserInfo()?.usuario?.gestor === true &&
              nomeTecnico &&
              nomeTecnico.trim() !== '' ? (
              <>
                {' > '}
                {nomeTecnico}
              </>
            ) : null}
          </h4>
          <Paper className={classes.paper}>
            <Grid container justifyContent="center">
              <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>Você não tem permissão para acessar esta página!</h2>
            </Grid>
          </Paper>
        </div>
      )
      }
      {nf && showDetails && <DetalheNotaFiscal microSigaID={microSigaID} nf={nf} handleClose={handleClose} />}
      {(usuarioEstoqueAtivo == true || usuarioGestor == true) && ContestacaoNF && showDetails && <ContestacaoNotaFiscal nf={ContestacaoNF} handleClose={handleClose} />}
    </>
  );
}
