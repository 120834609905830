import authService from '../auth/auth-service';
import config from '../../config';

const _configAPITecnico = config.configAPITecnico();

class PermissaoService {

  async listarPermissoes() {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Permissao/listar`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;

      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          data: data.items || data,
          total: data.total || data.length || 0,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage || 'Erro desconhecido.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar as permissões.',
      };
    }
  }

  async registroUsuariosComPermissoesAtivas(dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/PermissaoUsuario/listarUsuariosSecundariosComPermissoesAtivas?EmpresaID=${dados.idEmpresa}&Pagina=${dados.pagina}&Limite=${dados.limite}&Pesquisa=${dados.pesquisa}`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;

      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          data: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage || 'Erro desconhecido.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os usuários com permissões.',
      };
    }
  }

  async usuariosEmpresaComPermissoesAtivas(idEmpresa) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Usuario/listarUsuariosComPermissoesAtivas/${idEmpresa}`, {
        method: 'GET',
        headers: _headerToken,
      });

      const data = await response.json();
      let errorsMessage = null;

      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          data: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage || 'Erro desconhecido.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao buscar os usuários com permissões.',
      };
    }
  }

  async adicionarPermissaoUsuario(usuarioId, permissoes) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(`${_configAPITecnico.URI_API}/PermissaoUsuario`, {
        method: 'POST',
        headers: {
          ..._headerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          usuarioID: usuarioId,
          permissoes: permissoes,
        }),
      });

      const data = await response.json();
      let errorsMessage = null;
      if (data.errors) {
        errorsMessage = Object.values(data.errors).map((error) => error);
      }

      if (response.status === 200) {
        return {
          error: false,
          result: data,
        };
      }

      return {
        error: true,
        message: data.erro || errorsMessage || 'Erro desconhecido ao adicionar permissões.',
      };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao adicionar permissões ao usuário.',
      };
    }
  }

  async buscarPermissoesTecnico(tecnicoId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      const response = await fetch(
        `${_configAPITecnico.URI_API}/PermissaoUsuario/listarPermissaoUsuario/${tecnicoId}`,
        {
          method: 'GET',
          headers: _headerToken,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        return { error: true, message: errorData.mensagem || 'Erro ao buscar permissões do técnico.' };
      }

      return await response.json();
    } catch (error) {
      return { error: true, message: 'Erro ao buscar permissões do técnico.' };
    }
  }

  async atualizarPermissaoUsuario(permissaoId, dados) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/PermissaoUsuario/atualizar/${permissaoId}`, {
        method: 'PUT',
        headers: {
          ..._headerToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Permissoes: dados }),
      });

      const data = await response.json();

      if (response.status === 200) {
        return { error: false, result: data };
      }

      return { error: true, message: data.erro || 'Erro ao atualizar a permissão.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao atualizar a permissão.',
      };
    }
  }

  async deletarPermissao(permissaoId) {
    try {
      const _headerToken = authService.headerAPITecnico();
      let response = await fetch(`${_configAPITecnico.URI_API}/Permissao/${permissaoId}`, {
        method: 'DELETE',
        headers: _headerToken,
      });

      if (response.status === 204) {
        return { error: false, message: 'Permissão deletada com sucesso.' };
      }

      const data = await response.json();
      return { error: true, message: data.erro || 'Erro ao deletar a permissão.' };
    } catch (error) {
      return {
        error: true,
        message: 'Houve um erro ao deletar a permissão.',
      };
    }
  }
}

export default new PermissaoService();
