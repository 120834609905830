import { Badge, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, InputBase, Menu, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AirplayIcon from '@material-ui/icons/Airplay';
import CloseIcon from '@material-ui/icons/Close';
import ContactsIcon from '@material-ui/icons/Contacts';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FileCopy from '@material-ui/icons/FileCopy';
import LineWeight from '@material-ui/icons/LineWeight';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import ShopIcon from '@material-ui/icons/Shop';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import EngineeringIcon from '@mui/icons-material/Engineering';
import GroupIcon from '@mui/icons-material/Group';
import HandymanIcon from '@mui/icons-material/Handyman';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import PostAddTwoToneIcon from '@mui/icons-material/PostAddTwoTone';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TimelineIcon from '@mui/icons-material/Timeline';
import TodayIcon from '@mui/icons-material/Today';
import 'moment/locale/pt-br';
import React, { useEffect, useState } from 'react';
import MinhaContaDialog from '../../perfil/minha-conta';
import authService from '../../Services/auth/auth-service';
import noticiaService from '../../Services/Noticias/noticia-service';
import NotificacaoService from '../../Services/notificacoes/notificacoes-tecnico-service';
import usuarioService from '../../Services/Usuario/usuario-service';
import LoginModal from '../login/LoginModal';
import EventNoteIcon from '@mui/icons-material/EventNote';

var menuItems = [

  {
    text: 'Vendas',
    icon: <ShoppingBagIcon />,
    allowedRoles: ['revenda'],
    subMenuItems: [
      {
        text: 'Venda',
        icon: <ShoppingCartIcon />,
        url: '/venda',
        allowedRoles: ['revenda'],
      },
      {
        text: 'Oportunidade',
        icon: <ShopIcon />,
        url: '/oportunidades',
        allowedRoles: ['revenda'],
      },
      {
        text: 'Clientes',
        icon: <ContactsIcon />,
        url: '/clientes',
        allowedRoles: ['revenda'],
      },

    ],
  },

  {
    text: 'Financeiro',
    icon: <AttachMoneyIcon />,
    url: '/financeiro',
    allowedRoles: ['tecnico'],
  },


  {
    text: 'Gestor',
    icon: <TodayIcon />,
    url: '/gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Agenda',
    icon: <DateRangeIcon />,
    url: '/agenda',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notificações',
    icon: <MarkChatUnreadIcon />,
    url: '/notificacoes',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Auditoria',
    icon: <ReceiptLongIcon />,
    url: '/auditoria',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Inventário',
    icon: <InventoryIcon />,
    url: '/inventario',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Ordens de Serviço',
    icon: <ListAltIcon />,
    url: '/ordem-de-servico',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Comunicação',
    icon: <AirplayIcon />,
    url: '/comunicacao',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Notas Fiscais',
    icon: <DescriptionIcon />,
    url: '/notas-fiscais',
    allowedRoles: ['revenda', 'tecnico'],
  },
  {
    text: 'Estoque',
    icon: <ShoppingBagIcon />,
    allowedRoles: ['revenda', 'tecnico'],
    subMenuItems: [
      {
        text: 'Histórico de Estoque',
        icon: <TimelineIcon />,
        url: '/historico-do-estoque',
        allowedRoles: ['revenda', 'tecnico'],
      },
      {
        text: 'Meu Estoque',
        icon: <InventoryIcon />,
        url: '/estoque',
        allowedRoles: ['revenda', 'tecnico'],
      },
      {
        text: 'Solicitação de Reversa',
        icon: <SettingsBackupRestoreIcon />,
        url: '/solicitacao-reversa',
        allowedRoles: ['revenda', 'tecnico'],
      },

    ],
  },
  {
    text: 'Usuários',
    icon: <GroupIcon />,
    url: '/usuarios',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Central de Notificações',
    icon: <MarkChatUnreadIcon />,
    url: '/notificacoes-gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Itens de Remanufatura',
    icon: <HandymanIcon />,
    url: '/remanufatura',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Técnicos',
    icon: <EngineeringIcon />,
    url: '/cadastro-tecnico',
    allowedRoles: ['tecnico', 'revenda'],
  },
  {
    text: 'Notícias',
    icon: <LineWeight />,
    url: '/noticias',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Manuais',
    icon: <FileCopy />,
    url: '/manuais',
    allowedRoles: ['revenda', 'gestor', 'tecnico'],
  },
  {
    text: 'Liberação do Estoque',
    icon: <LockOpenIcon />,
    url: '/liberacao-estoque-gestor',
    allowedRoles: ['gestor'],
  },
  {
    text: 'Configurar Permissões',
    icon: <PostAddTwoToneIcon />,
    url: '/tecnico-permissoes',
    allowedRoles: ['tecnico'],
  },
  {
    text: 'Agendas do Técnico',
    icon: <EventNoteIcon />,
    url: '/agendas-do-tecnico',
    allowedRoles: ['gestor'],
  },
];

menuItems.sort((a, b) => a.text.localeCompare(b.text));

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  listagemNotificacao: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '16px',
    textAlign: 'center',
    padding: 5,
    fontWeight: 'bold'
  },
  submenu: {
    paddingLeft: theme.spacing(3),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1450,
    background: '#209BDE',
  },
  appBarShift: {
    width: 'calc(100% - 240px)',
    marginLeft: 240,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  button: {
    color: 'white',
    border: '1px solid white',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: 240,
    flexShrink: 0
  },
  logo: {
    margin: '20px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerPaper: {
    width: 280,
    background: '#209BDE',
    color: 'white',
    marginTop: '64px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    },
    // zIndex: 1100,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -240
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  grow: {
    flexGrow: 1,
  },
  menu: {
    marginTop: '60px'
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'white',
  },
  searchInput: {
    color: 'white',
    marginLeft: theme.spacing(1),
    flex: 1,
    marginTop: 5
  },
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.3s ease-in-out',
    opacity: 0,
    pointerEvents: 'none',
    zIndex: 1200
  },
  backdropVisible: {
    opacity: 1,
    pointerEvents: 'auto',
  },
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState({});
  const [searchText, setSearchText] = useState('');
  const [carregando ,setCarregando ] = useState('');
  const [usuarioInfo, setUsuarioInfo] = useState(null);
  const [_notificacao, setNotificacao] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const [totalNotificacoes, setTotalNotificacoes] = useState(0);
  const [, setOpenDialog] = useState(false);
  const [usuarioEstoqueAtivo, setUsuarioEstoqueAtivo] = useState(false);
  const anchorRef = React.useRef(null);
  const [openAnch, setOpenAnch] = useState(false);
  const [pagina, setPagina] = useState(0);
  const [quantidade, ] = useState(8);
  const [pesquisa, ] = useState('');
  const tecnicoId = authService?.getUserInfo()?.usuario?.id;
  const inactivityLimit = 900;
  const intervalCheck = 50; 2
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [backdropNotificacao, setBackdropNotificacao] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const [firstLoginUser, setFirstLoginUser] = useState(null);
  const [menuUserAnchor, setMenuUserAnchor] = useState(null);
  const [openPerfilDialog, setOpenPerfilDialog] = useState(false);
  const [, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (storedUserInfo) {
        setUsuarioInfo(storedUserInfo.usuario);
      }
      await buscarUsuarioEstoqueAtivo();
      setLoading(false); 
    };
    fetchUserData();
  }, []);
  const verificarPermissao = () => {
    if (!usuarioInfo) return {}; 
    const permissaoGlobal = usuarioInfo.permissaoUsuario[0]; 
    const tecnico = usuarioInfo.tecnico;
    const empresa = usuarioInfo.empresa;
  
    if (!tecnico && !empresa) {
      return { 
        cod_configurar_permissoes: true, 
        cod_comunicacao: true, 
        cod_financeiro: true, 
        cod_historico_estoque: true, 
        cod_manuais: true, 
        cod_estoque: true, 
        cod_notas_fiscais: true, 
        cod_noticias: true, 
        cod_notificacoes: true, 
        cod_ordens_servico: true, 
        cod_solicitacao_reversa: true ,
        cod_agenda: true,
        cod_tecnicos: true,
      }; 
    }
  
    const permissoesLista = permissaoGlobal.permissoesLista || [];
    const permissoes = {
      cod_configurar_permissoes: permissoesLista.includes('cod_configurar_permissoes'),
      cod_comunicacao: permissoesLista.includes('cod_comunicacao'),
      cod_financeiro: permissoesLista.includes('cod_financeiro'),
      cod_historico_estoque: permissoesLista.includes('cod_historico_estoque'),
      cod_manuais: permissoesLista.includes('cod_manuais'),
      cod_estoque: permissoesLista.includes('cod_estoque'),
      cod_notas_fiscais: permissoesLista.includes('cod_notas_fiscais'),
      cod_noticias: permissoesLista.includes('cod_noticias'),
      cod_notificacoes: permissoesLista.includes('cod_notificacoes'),
      cod_ordens_servico: permissoesLista.includes('cod_ordens_servico'),
      cod_solicitacao_reversa: permissoesLista.includes('cod_solicitacao_reversa'),
      cod_agenda: permissoesLista.includes('cod_agenda'),
      cod_tecnicos: permissoesLista.includes('cod_tecnicos')
    };
  
    return permissoes;
  };

  const handleUserMenuClose = () => {
    setMenuUserAnchor(null);
  };


  const handlePerfilClick = () => {
    setOpenPerfilDialog(true);
  };

  const handleClosePerfilDialog = () => {
    setOpenPerfilDialog(false);
  };

  const handleSairClick = () => {
    authService.logOut();
    window.location.href = '/';
  };

  useEffect(() => {
    let storedFirstLoginUser = localStorage.getItem('firstLoginUser');

    if (!storedFirstLoginUser) {
      const userInfo = authService.getUserInfo();
      if (userInfo && userInfo.usuario && userInfo.usuario.email) {
        localStorage.setItem('firstLoginUser', userInfo.usuario.email);
        setFirstLoginUser(userInfo.usuario.email);
      }
    } else {
      setFirstLoginUser(storedFirstLoginUser);
    }
  }, []);

  const handleToggleSubmenu = (text) => {
    setSubmenuOpen((prev) => ({ ...prev, [text]: !prev[text] }));
  };


  const resetInactivityTimer = () => {
    setLastActivityTime(Date.now());
  };
  useEffect(() => {
    if (isLoginRequired) {
      setOpenAnch(false);
      setBackdropNotificacao(false);
    }
  }, [isLoginRequired]);
  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    const checkInactivity = () => {
      const currentTime = Date.now();
      const timeSinceLastActivity = (currentTime - lastActivityTime) / 1000;


      if (timeSinceLastActivity > inactivityLimit && !isLoginRequired) {
        authService.logOut();
        handleToggle();
        setIsLoginRequired(true);
      }
    };

    let intervalId;
    if (!isLoginRequired) {
      intervalId = setInterval(checkInactivity, intervalCheck * 1000);
    }

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      clearInterval(intervalId);
    };
  }, [lastActivityTime, isLoginRequired]);

  const handleLoginSuccess = (usuario) => {
    setUsuarioInfo(usuario);
    setIsLoginRequired(false);
    localStorage.setItem('loginTime', Date.now());
  };

  useEffect(() => {
    if (isLoginRequired) {
      setNotificacao(null);
      setOpenDialog(false);
      setBackdropNotificacao(false);
    }
  }, [isLoginRequired]);

 const filteredMenuItems = usuarioInfo
  ? menuItems
      .filter((item) => {
        const isGestor = usuarioInfo.gestor === true;
        const isRevenda = usuarioInfo.revenda === true;
        const isTecnico = !isGestor && !isRevenda;

        if (item.allowedRoles.includes('gestor') && isGestor) return true;
        if (item.allowedRoles.includes('revenda') && isRevenda) return true;
        if (item.allowedRoles.includes('tecnico') && isTecnico) return true;

        return false;
      })
      .filter((item) => {
        if (!usuarioEstoqueAtivo && ['Meu Estoque', 'Solicitação de Reversa', 'Notas Fiscais', 'Histórico de Estoque'].includes(item.text)) {
          return false;
        }
        return true;
      })
      .filter((item) => {
        const {
          cod_configurar_permissoes,
          cod_comunicacao,
          cod_financeiro,
          cod_historico_estoque,
          cod_manuais,
          cod_estoque,
          cod_notas_fiscais,
          cod_noticias,
          cod_notificacoes,
          cod_ordens_servico,
          cod_solicitacao_reversa,
          cod_tecnicos,
          cod_agenda
        } = verificarPermissao();

        if (item.text === 'Configurar Permissões' && !cod_configurar_permissoes) {
          return false;
        }
        if (item.text === 'Comunicação' && !cod_comunicacao) {
          return false;
        }
        if (item.text === 'Financeiro' && !cod_financeiro) {
          return false; 
        }
        if (item.text === 'Histórico de Estoque' && !cod_historico_estoque) {
          return false; 
        }
        if (item.text === 'Manuais' && !cod_manuais) {
          return false; 
        }
        if (item.text === 'Estoque' && !cod_estoque) {
          return false; 
        }
        if (item.text === 'Notas Fiscais' && !cod_notas_fiscais) {
          return false;
        }
        if (item.text === 'Notícias' && !cod_noticias) {
          return false; 
        }
        if (item.text === 'Notificações' && !cod_notificacoes) {
          return false; 
        }
        if (item.text === 'Ordens de Serviço' && !cod_ordens_servico) {
          return false;
        }
        if (item.text === 'Solicitação de Reversa' && !cod_solicitacao_reversa) {
          return false; 
        }
        if (item.text === 'Técnicos' && !cod_tecnicos) {
          return false; 
        }
        if (item.text === 'Agenda' && !cod_agenda) {
          return false;
        }
        return true; 
      })
  : []; 

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSair = () => {
    setNotificacao(null);
    setOpenDialog(false);
    setNotificacoes([]);
    setBackdropNotificacao(false);

    authService.logOut();
    window.location.href = '/';
  };

  const handleToggle = () => {
    if (!isLoginRequired) {
      setOpenAnch((prevOpen) => !prevOpen);
      setBackdropNotificacao((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenAnch(false);
    setBackdropNotificacao(false);
    setCarregando(false);
  };

  const handleDialogNotificacaoClose = () => {
    setNotificacao(null);
    setOpenDialog(false);
    setCarregando(false);
  };
  const handleUserMenuOpen = (event) => {
    if (menuUserAnchor) {
      setMenuUserAnchor(null);
    } else {
      setMenuUserAnchor(event.currentTarget);
    }
  };

  const handleDialogNotificacao = (notificacao) => {
    handleToggle();
    setNotificacao(notificacao);
    notificacaoLida(notificacao.id);
    setOpenDialog(true);
  };

  const buscarUsuarioEstoqueAtivo = async () => {
    if (!authService?.getUserInfo()?.usuario?.id) return;

    const response = await usuarioService.usuarioEstoqueAtivo(authService?.getUserInfo()?.usuario?.id);
    if (!response.error) {
      setUsuarioEstoqueAtivo(response);
    }
  }

  useEffect(() => {
    buscarUsuarioEstoqueAtivo();
    setUsuarioInfo(JSON.parse(localStorage?.getItem('userInfo'))?.usuario);
    consultarNoticias(tecnicoId, pagina, quantidade, pesquisa);
  }, [totalNotificacoes]);
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo || !userInfo.usuario) {
      handleToggle();
      setIsLoginRequired(true);
    } else {
      setUsuarioInfo(userInfo.usuario);
    }
  }, []);

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    if (userInfo && userInfo.usuario) {
      setUsuarioInfo(userInfo.usuario);
    } else {
      setOpenDialog(true);
    }
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const consultarNoticias = async (tecnicoId, pagina, quantidade, pesquisa) => {
    if (!tecnicoId) return;

    setCarregando(true);
    const dados = {
      tecnicoId: tecnicoId,
      pagina: pagina || 0,
      limite: quantidade || 4,
      pesquisa: pesquisa || '',
    };

    const noticias = await noticiaService.listarNotificacoesNaoLidasTecnicos(dados);
    if (noticias) {
      const novasNotificacoes = noticias.dados.filter(
        (novaNotificacao) =>
          !notificacoes.some((notificacao) => notificacao.id === novaNotificacao.id)
      );
      setNotificacoes((prevNotificacoes) => [...prevNotificacoes, ...novasNotificacoes]);
      setTotalNotificacoes(noticias.total);
    }
    setCarregando(false);
  };

  const carregarMaisNotificacoes = () => {
    const novaPagina = pagina + 1;
    setPagina(novaPagina);
    consultarNoticias(tecnicoId, novaPagina, quantidade, pesquisa);
  };

  const notificacaoLida = async (idNotificacao) => {
    const idsNotificacoes = [idNotificacao];

    const response = await NotificacaoService.visualizarNotificacao(idsNotificacoes);
    if (!response.error) {
      setNotificacoes((prevNotificacoes) =>
        prevNotificacoes.filter((notificacao) => notificacao.id !== idNotificacao)
      );
      setTotalNotificacoes((prevTotal) => prevTotal - 1);
    }
  };


  const dialogNotificacao = (notificacao) => {
    if (!notificacao || isLoginRequired) return null;

    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleDialogNotificacaoClose();
          }
        }}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Notificação
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={handleDialogNotificacaoClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers style={{ marginTop: '10px' }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography
                component="span"
                variant="subtitle1"
                style={{
                  fontWeight: 500,
                  color: '#209BDE',
                  wordBreak: 'break-word',
                }}
              >
                {notificacao.tituloNotificacao}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                component="span"
                variant="body2"
                style={{
                  color: '#555',
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {notificacao.textoNotificacao}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="caption"
                style={{ color: '#999', float: 'right' }}
              >
                {new Date(notificacao.dataCadastro).toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            fullWidth
            onClick={handleDialogNotificacaoClose}
            style={{
              marginBottom: '10px',
              marginTop: '10px',
              width: '40%',
              height: 55,
            }}
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            disabled={isLoginRequired}
            edge="start"
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <a onClick={() => {
            const userInfo = authService.getUserInfo();
            if (userInfo.usuario.gestor) {
              window.location.assign('/gestor');
            } else if (userInfo.usuario.revenda) {
              window.location.assign('/revenda');
            } else {
              window.location.assign('/agenda');
            }

          }}>
            <img src="../logo/LogotipoOmnilinkWHITE.png" height="45" alt="Logo" />
          </a>

          <div className={classes.grow} />
          <div style={{ display: 'flex' }}>
            <div style={{ paddingRight: 10 }}>
              <IconButton
                ref={anchorRef}
                aria-controls="fade-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleToggle}
                disabled={isLoginRequired}
              >
                <Badge badgeContent={totalNotificacoes} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>

            <div
              className={`${classes.backdrop} ${backdropNotificacao ? classes.backdropVisible : ''}`}
              onClick={handleClose}
              style={{ display: isLoginRequired ? 'none' : 'block' }}
            />
            <Menu
              keepMounted
              open={openAnch}
              onClose={handleClose}
              anchorEl={anchorRef.current}
              component="nav"
              className={classes.menu}
              id="menu-list-grow"
              disablePortal
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  width: mobile ? '100%' : 350,
                  position: 'absolute',
                  zIndex: 1300,
                },
              }}
            >
              <List component="nav" aria-label="notification menu">
                <Box
                  style={{
                    backgroundColor: '#209BDE',
                    color: 'white',
                    padding: '16px',
                    textAlign: 'center',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    marginTop: '-16px',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Notificações
                  </Typography>
                </Box>
                <Divider style={{ backgroundColor: '#E0E0E0', height: '1px' }} />
                {notificacoes?.length > 0 ? (
                  notificacoes.map((x) => (
                    <React.Fragment key={x.id}>
                      <ListItem
                        button
                        alignItems="flex-start"
                        onClick={() => handleDialogNotificacao(x)}
                        className={classes.listItem}
                        style={{ padding: '10px 15px', transition: 'background 0.3s' }}
                        sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="subtitle1"
                              style={{
                                fontWeight: 'bold',
                                color: '#209BDE',
                                wordBreak: 'break-word',
                              }}
                            >
                              {x.tituloNotificacao.length > 20
                                ? `${x.tituloNotificacao.substring(0, 20)}...`
                                : x.tituloNotificacao}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                style={{ wordBreak: 'break-word' }}
                              >
                                {x.textoNotificacao.length > 20
                                  ? `${x.textoNotificacao.substring(0, 20)}...`
                                  : x.textoNotificacao}
                              </Typography>
                              <Typography
                                component="span"
                                variant="caption"
                                color="textSecondary"
                                style={{ display: 'block', marginTop: 10, float: 'right' }}
                              >
                                {new Date(x.dataCadastro).toLocaleString()}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem alignItems="center" style={{ justifyContent: 'center', padding: '20px' }}>
                    <ListItemText
                      secondary={
                        <Typography component="span" variant="body2" color="textSecondary">
                          Você não possui notificações.
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
                {notificacoes.length < totalNotificacoes && (
                  <Button
                    fullWidth
                    onClick={carregarMaisNotificacoes}
                    disabled={carregando}
                    style={{ marginTop: '10px', textTransform: 'none', color: '#1c69ad', }}
                  >

                    <Typography className={classes.listagemNotificacao}>{carregando ? 'Carregando...' : 'Ver Mais'}</Typography>
                  </Button>
                )}
              </List>
            </Menu>


            <IconButton
              onClick={handleUserMenuOpen}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              keepMounted
              onClose={handleUserMenuClose}
              open={Boolean(menuUserAnchor)}
              anchorEl={menuUserAnchor}
              component="nav"
              className={classes.menu}
              id="menu-list-grow"
              disablePortal
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  width: mobile ? '100%' : 150,
                  position: 'absolute',
                  zIndex: 1300,
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  handleUserMenuClose(); // Fecha o menu
                  handlePerfilClick(); // Abre o diálogo de perfil
                }}
              >
                <ListItemIcon>
                  <AccountBoxIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Perfil</Typography>
              </MenuItem>
              <MenuItem onClick={handleSairClick}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Sair</Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={handleDrawerClose}
      >
        <ListItem style={{ background: '#008bd7' }}>
          <InputBase
            className={classes.searchInput}
            placeholder="Buscar..."
            inputProps={{ 'aria-label': 'search' }}
            startAdornment={
              <InputAdornment position="start" style={{ marginLeft: '-10px' }}>
                <SearchIcon style={{ color: 'white' }} />
              </InputAdornment>
            }
            value={searchText}
            onChange={handleSearchChange}
          />
        </ListItem>

        <Divider />

        <Box>
          {filteredMenuItems.map((item) => (
            <div key={`${item.text}-${item.url}`}>
              <ListItem
                button
                onClick={() => {
                  if (item.text === 'Sair') {
                    handleSair();
                  } else if (item.subMenuItems) {
                    handleToggleSubmenu(item.text);
                  } else {
                    window.location.href = item.url;
                  }
                }}
                style={{ marginRight: '16px' }}
              >
                <ListItemIcon style={{ color: 'white' }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
                {item.subMenuItems && (
                  <IconButton edge="end">
                    {submenuOpen[item.text] ? <ExpandLess style={{ color: 'white' }} /> : <ExpandMore style={{ color: 'white' }} />}
                  </IconButton>
                )}
              </ListItem>

              {item.subMenuItems && submenuOpen[item.text] && (
                <List className={classes.submenu}>
                  {item.subMenuItems.map((submenuItem) => (
                    <ListItem
                      key={`${submenuItem.text}-${submenuItem.url}`}
                      button
                      onClick={() => {
                        window.location.href = submenuItem.url;
                      }}
                    >
                      <ListItemIcon style={{ color: 'white' }}>{submenuItem.icon}</ListItemIcon>
                      <ListItemText primary={submenuItem.text} />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
          <div style={{ marginBottom: '70px' }}></div>
        </Box>
      </Drawer>
      <MinhaContaDialog open={openPerfilDialog} handleClose={handleClosePerfilDialog} />
      {isLoginRequired && (
        <LoginModal
          style={{ zIndex: 1500 }}
          open={isLoginRequired}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          onClose={() => setIsLoginRequired(false)}
          onLoginSuccess={handleLoginSuccess}
          firstLoginUser={firstLoginUser}
        />
      )}
      <main className={classes.content}>
        <div className={classes.drawerHeader} />
      </main>
      {!isLoginRequired && _notificacao && dialogNotificacao(_notificacao)}
    </div>
  );
}
