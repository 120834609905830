import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Paper, makeStyles, Grid } from '@material-ui/core';
import MenuComponent from '../components/menu/menu-component';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import PhoneIcon from '@material-ui/icons/Phone';
import noticiasService from '../Services/Noticias/noticia-service';
import authService from '../Services/auth/auth-service';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'column',
		padding: '15px 20px 15px',
		borderRadius: 10,
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			padding: '20px 10px'
		}
	},
    progress: {
        color: '#209BDE',
    },
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	mensagemStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	}
}));

export default function NoticiaPage() {
	const classes = useStyles();
	const [listanoticias, setNoticias] = useState([]);
	const [loading, setLoading] = useState(false);
	const [temPermissao, setTemPermissao] = useState(true);

	useEffect(() => {
		const usuarioInfo = authService.getUserInfo().usuario;
		const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
		const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
		const empresa = usuarioInfo.empresa;
		const tecnico = usuarioInfo.tecnico;
		let temPermissao = true;

		if (empresa || tecnico) {
			temPermissao = permissaoAgendaGlobal?.some(permissao => permissao.permissoesLista?.includes('cod_noticias'));
		} else {
			temPermissao = true;
		}

		if (!temPermissao) {
			setTemPermissao(false);
		} else {
			setTemPermissao(true);
		}
		if (tecnicoPrestador && !temPermissao) {
			return;
		}

	}, []);

	useEffect(() => {
		noticias();
	}, []);

	const noticias = async () => {
		setLoading(true);

		try {
			let noticiasData = await noticiasService.noticias();

			if (noticiasData) {
				let _noticias = noticiasData.map((m) => ({
					id: m.id || '',
					txtTitulo: m.txtTitulo || '',
					icone: m.icone || '',
				}));

				setNoticias(_noticias);
			}
		} catch (error) {
			console.error('Erro ao buscar notícias:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
		{temPermissao ? (
			<div>
				<MenuComponent />
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Notícias</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => {
								const userInfo = authService.getUserInfo();
								if (userInfo.usuario.gestor) {
									window.location.assign('/gestor');
								} else {
									window.location.assign('/revenda');
								}
							}}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span> {'>'} Notícias
					</h4>

					<Paper className={classes.paper}>
						{loading ? (
							<Grid container alignItems="center" justifyContent="center">
								<CircularProgress className={classes.progress} />
							</Grid>
						) : (
							<List>
								{listanoticias.length > 0 ? (
									listanoticias.map((m) => (
										<ListItem key={m.id}>
											<ListItemAvatar>
												<Avatar>
													<NewReleasesIcon />
												</Avatar>
											</ListItemAvatar>
											<ListItemText primary={m.txtTitulo} />
										</ListItem>
									))
								) : (
									<Box justifyContent="center" display="flex">
										<p>Não há itens na lista!</p>
									</Box>
								)}
							</List>
						)}
					</Paper>

					<Box>
						<Box textAlign="center" fontWeight={800} m={1}>
							Fale com a Omnilink
						</Box>

						<Box fontSize={16} justifyContent="center" display="flex">
							<Box>
								<PhoneIcon />
							</Box>
							<Box>0800-888-6200</Box>
						</Box>
					</Box>
				</div>
			</div>
		) : (
			<>
				<MenuComponent />
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Notificações</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => (window.location.href = '/agenda')}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span> {'>'} Notificações
					</h4>

					<Paper className={classes.paper}>
						<Grid container justifyContent="center">
							<h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
								Você não tem permissão para acessar esta página!
							</h2>
						</Grid>
					</Paper>
				</div>
			</>
		)}
		</>
	);
}
