import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@mui/material';
import debounce from 'lodash/debounce';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import 'moment/locale/pt-br';
import React, { Children, cloneElement, useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import CustomAlert from '../../components/alert/CustomAlert';
import BlueButton from '../../components/button/BlueButton';
import { LinearProgressBlue } from '../../components/progress/LinearProgressBlue';
import agendaService from '../../Services/Agenda/agenda-service';
import authService from '../../Services/auth/auth-service';
import ordemServicoServico from '../../Services/OrdemServico/ordem-servico-servico';
import perfilTecnicoService from '../../Services/perfilTecnico/perfil-tecnico-services';
import { dateStrings } from '../../util/dateStrings';
import DailyAgenda from './daily-agenda';

export const useStyles = makeStyles(() => ({
  dateCell: {
    borderRight: '0.5px solid #DDDDDD',
    flex: '1 1 0%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
  },
  acessoRapidoStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#1C69AD',
    fontSize: '17px',
    fontWeight: 'bold',
    textAlign: 'start',
    padding: '10px 5px',
    marginTop: '0px',
    marginBottom: '8px',
  },
}));

const EventCalendar = () => {

  const { WEEK_DAYS, MONTHS } = dateStrings;
  const theme = useTheme();
  const classes = useStyles();
  const localizer = momentLocalizer(moment);
  const [loading, setLoading] = useState(false);
  const [tipoAlert, setTipoAlert] = React.useState('error');
  const [abrirAlert, setAbrirAlert] = React.useState(false);
  const [eventos, setEventos] = useState([]);
  const [eventosAgenda, setEventosAgenda] = useState([]);
  const [eventosAgendaDoDia, setEventosAgendaDoDia] = useState([]);
  const [dataAgenda, setDataAgenda] = useState(new Date());
  const [, setOrdensServicoAtrasadas] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tecnicosAprovados, setTecnicosAprovados] = useState([]);
  const [openDialogAgendamento, setOpenDialogAgendamento] = useState(false);

  const [tecnicoSelecionado, setTecnicoSelecionado] = useState('');
  const [loadingDetalhes, setLoadingDetalhes] = useState(false);
  const [openDialogConfirmaInicioAtividade, setOpenDialogConfirmaInicioAtividade] = useState(false);
  const [, setAlert] = useState({ open: false, message: '', type: '' });
  const [atividadeServico, setAtividadeServico] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedNa, setSelectedNa] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [totalEventosAtrasados, setTotalEventosAtrasados] = useState(0);
  const [, setSelectDisabled] = useState(false);
  const [, setAlertOpen] = useState(false);
  const [, setAlertMessage] = useState({ title: '', body: '' });
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [mensagemAlert, setMensagemAlert] = React.useState('');
  const tecnicoPrestador = authService.getUserInfo().usuario.tecnico;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const handleIniciarAtividadeDeServico = async () => {
    const iniciaAtividadeDeServico = await ordemServicoServico.iniciarAtividadeDeServico(
      atividadeServico.activityId,
    );

    if (iniciaAtividadeDeServico) {
      if (iniciaAtividadeDeServico.erro) {
        setAlert({ open: true, message: 'Erro ao iniciar atividade de serviço!', type: 'error' });
      } else {
        history.push({
          pathname: `/ordemservico/${atividadeServico.na}`,
        });
      }
    } else {
      setAlert({ open: true, message: 'A atividade já foi iniciada!', type: 'fail' });
    }
  };
  const buscarNas = async (value) => {
    if (!value || value.length < 3) {
      setOptions([]);
      return;
    }
    setLoadingSearch(true)
    const areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;

    try {
      const resultado = await agendaService.buscarDadosNA(areaAtendimentoID, value);

      if (resultado && !resultado.error) {
        setOptions(
          resultado.map((item) => ({
            numeroNA: item.numeroNA || '',
            atividadeId: item.atividadeId || '',
            nome: item.nome || '',
          }))
        );
      } else {
        setOptions([]);
        setLoadingSearch(false)
      }
    } catch (error) {
      setOptions([]);
    }
  };

  const debouncedBuscarNas = useCallback(debounce(buscarNas, 300), []);

  const buscarDetalhesOS = async (na, areaAtendimentoID) => {
    setLoadingDetalhes(true);
    try {
      const response = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);

      if (response && response.length > 0) {
        setAtividadeServico(response[0]);
        setOpenDialogAgendamento(true);
      } else {
        setMensagemAlert({
          title: 'Atenção!',
          body: 'Não foi possível obter número da Ordem de Serviço! Entre em contato com suporte, você será direcionado a pagina principal'
        });
        setTipoAlert('fail');
        setAbrirAlert(true);
        setOpenDialogAgendamento(false);
      }
    } catch (error) {
      setAlertMessage({ title: 'Erro!', body: 'Erro ao tentar buscar a ordem de serviço ou NA.' });
      setTipoAlert('error');
      setAlertOpen(true);
      setOpenDialogAgendamento(false);
    } finally {
      setLoadingDetalhes(false);
    }
  };

  const handleChange = async (selectedOption) => {
    setLoading(true);

    try {
      const response = await perfilTecnicoService.cadastrarTecnicoOS({
        osNumero: atividadeServico?.os,
        tecnicoId: selectedOption.value,
      });

      if (!response.error) {
        setTecnicoSelecionado(selectedOption);
        setSelectDisabled(true);


      } else {
        setMensagemAlert({ title: 'Atenção!', body: 'Erro ao cadastrar técnico. Tente novamente.' });
        setTipoAlert('fail');
        setAbrirAlert(true);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao conectar com o servidor' });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setLoading(false);
    }
  };



  const formats = {
    monthHeaderFormat: (day) => `${MONTHS[day.getMonth()]} de ${day.getFullYear()}`,
    dayHeaderFormat: (day) =>
      `${WEEK_DAYS[day.getDay()]}, ${day.getDate()} de ${MONTHS[day.getMonth()]}`,
    dayRangeHeaderFormat: ({ start, end }) =>
      `${start.getDate()} à ${end.getDate()} de ${MONTHS[start.getMonth()]}`,
    agendaDateFormat: (day) =>
      `${WEEK_DAYS[day.getDay()].slice(0, 3)}, ${day.getDate()} de ${MONTHS[day.getMonth()]}`
  };

  const handleDetalhesOrdemServico = () => {
    history.push({
      pathname: `/ordemservico/${atividadeServico.na}`,
    });
  };

  const messages = {
    allDay: 'Todos os dias',
    previous: '< Anterior',
    next: 'Próximo >',
    today: 'Hoje',
    month: 'Mês',
    week: 'Semana',
    day: 'Dia',
    agenda: 'Agenda',
    date: 'Data',
    time: 'Hora',
    event: 'Evento',
    noEventsInRange: 'Não foram encontradas atividades nesse período.',
  };
  useEffect(() => {
    const fetchTecnicos = async () => {
      const usuarioInfo = authService.getUserInfo()?.usuario;

      if (!usuarioInfo) {
        console.error('Usuário não autenticado.');
        return;
      }

      try {
        const response = await perfilTecnicoService.buscarTecnicoEmpresa(usuarioInfo.id);

        if (response && !response.error) {
          const formattedTecnicos = response.map((tecnico) => ({
            value: tecnico.idTecnico,
            label: tecnico.nomeTecnico,
          }));

          setTecnicosAprovados(formattedTecnicos);

          const tecnicoNome = atividadeServico?.nomeTecnico;
          if (tecnicoNome) {
            const tecnicoEncontrado = formattedTecnicos.find(
              (tecnico) => tecnico.label === tecnicoNome
            );
            setTecnicoSelecionado(tecnicoEncontrado || null);
          }
        } else {
          console.warn('Erro ao buscar técnicos:', response?.message || 'Erro desconhecido.');
        }
      } catch (error) {
        console.error('Erro ao buscar técnicos:', error);
      }
    };

    fetchTecnicos();
  }, [atividadeServico]);


  useEffect(() => {
    loadCalendario();
    loadEventosTotal();
    setOpenDialog();
  }, []);
  useEffect(() => {
    setOpenDialog(false);
  }, [dataAgenda]);

  useEffect(() => {
    verificaServicosPendentes();
    verificaEventosDoDia();
  }, [eventos, eventosAgenda]);

  const verificaEventosDoDia = () => {
    let atividadesDoDia = eventos.filter((evento) => {
      const dataEvento = moment(evento.previsaoInicio).add(-3, 'hours').toDate();
      dataEvento.setHours(0, 0, 0, 0);
      dataAgenda.setHours(0, 0, 0, 0);

      return (
        dataEvento.getTime() === dataAgenda.getTime()
      );
    });

    atividadesDoDia.sort(function (a, b) {
      const dataHoraA = a.inicio ? new Date(a.inicio) : new Date(a.previsaoInicio);
      const dataHoraB = b.inicio ? new Date(b.inicio) : new Date(b.previsaoInicio);

      return dataHoraA - dataHoraB;
    });

    setEventosAgendaDoDia(atividadesDoDia);
  }

  const verificaServicosPendentes = () => {
    const today = moment().startOf('day');
    const pastDueOrders = eventosAgenda.filter(
      (evento) => evento.status === 3 && moment(evento.end).isBefore(today)
    );

    setOrdensServicoAtrasadas(pastDueOrders.length);
  };

  const abreAtividadesAgenda = useCallback(async ({ action, slots }) => {
    if (action !== 'click') return;

    const selectedDate = slots[0];
    selectedDate.setHours(0, 0, 0, 0);

    let atividadesDoDia = eventos.filter((evento) => {
      const previsaoInicio = moment(evento.previsaoInicio).add(-3, 'hours').toDate();

      if (previsaoInicio == null) return false;

      const dataEvento = new Date(previsaoInicio);
      dataEvento.setHours(0, 0, 0, 0);

      return dataEvento.getTime() === selectedDate.getTime();
    });

    atividadesDoDia.sort(function (a, b) {
      const dataHoraA = a.inicio ? new Date(a.inicio) : new Date(a.previsaoInicio);
      const dataHoraB = b.inicio ? new Date(b.inicio) : new Date(b.previsaoInicio);

      return dataHoraA - dataHoraB;
    });

    setEventosAgendaDoDia(atividadesDoDia);
    setDataAgenda(selectedDate);
  }, [eventos]);

  const dayPropGetter = useCallback(
    (date) => {
      const dayEvents = eventosAgenda.filter((evento) =>
        moment(evento.start).isSame(date, 'day')
      );

      return {
        ...(dayEvents.length > 0 && {
          className: 'event-day',
          style: {
            backgroundColor: dataAgenda.getTime() === date.getTime() ? '#81F781' : '#209BDE',
            color: 'white',
            cursor: 'pointer',
          },
        }),
      };
    },
    [eventosAgenda, dataAgenda]
  );

  const eventPropGetter = () => {
    return {
      style: {
        display: 'none',
      },
    };
  };
  useEffect(() => {
    if (atividadeServico?.nomeTecnico && tecnicosAprovados.length > 0) {
      const tecnicoEncontrado = tecnicosAprovados.find(
        (tecnico) => tecnico.label?.toLowerCase() === atividadeServico.nomeTecnico?.toLowerCase()
      );

      setTecnicoSelecionado(tecnicoEncontrado || null);
    }
  }, [atividadeServico, tecnicosAprovados]);


  const handleEventClickForMobile = (dataSelecionada) => {
    if (!mobile) return;

    abreAtividadesAgenda({ action: 'click', slots: [dataSelecionada] });
  };

  const EventWrapper = ({ children, value, onSelectSlot }) => {
    const dayEvents = eventosAgenda.filter(
      (evento) => moment(evento.start).isSame(value, 'day')
    );

    const wrappedChildren = cloneElement(Children.only(children), {
      onTouchEnd: () => onSelectSlot({ action: 'click', slots: [value] }),
      style: {
        className: `${children}`
      }
    });

    if (dayEvents.length > 0) {
      return (
        <div
          onClick={handleEventClickForMobile.bind(this, value)}
          className={`${classes.dateCell}`}
          style={{
            backgroundColor: dataAgenda.getTime() === value.getTime() ? '#81F781' : dayEvents.length > 0 ? '#209BDE' : 'transparent',
            cursor: 'pointer',
          }}
        >
          {wrappedChildren}
          {dayEvents.length > 0 && <p style={{ color: 'white' }}>{dayEvents.length} <span>Serv.</span></p>}
        </div>
      );
    } else {
      return (
        <div onClick={handleEventClickForMobile.bind(this, value)} className={`${classes.dateCell}`}
          style={{ cursor: 'pointer' }}>
          {wrappedChildren}
        </div>
      );
    }
  }

  const handleNavigate = async (e) => {
    const inicio = moment(e).format('YYYY-MM-01T00:00:00');
    const fim = moment(e).format('YYYY-MM-') + moment(e).daysInMonth() + 'T23:59:59';

    await consultaEventos(inicio, fim);
  };

  const consultaEventosTotal = async () => {
    try {
      setLoading(true);

      const usuarioInfo = authService.getUserInfo().usuario;
      const areaAtendimentoID = usuarioInfo.tz_area_atendimento_microsigaid;
      const loginTecnico = tecnicoPrestador ? usuarioInfo.login : '';

      const eventosResult = await agendaService.AgendaTecnicoTotal(areaAtendimentoID, loginTecnico);

      setTotalEventosAtrasados(eventosResult);

      if (eventosResult > 0) {
        setOpenDialog(true);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const consultaEventos = async (dtIni, dtFim) => {
    try {
      setLoading(true);

      const usuarioInfo = authService.getUserInfo().usuario;
      const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
      const temPermissao = permissaoAgendaGlobal?.some(permissao =>
        permissao.permissoesLista?.includes('cod_agenda_compartilhada')
      );

      const areaAtendimentoID = usuarioInfo.tz_area_atendimento_microsigaid;
      const loginTecnico = tecnicoPrestador ? usuarioInfo.login : '';

      const eventosResult = await agendaService.AgendaTecnico(
        areaAtendimentoID,
        dtIni,
        dtFim,
        temPermissao ? '' : loginTecnico
      );

      if (eventosResult) {
        let _eventos = [];
        eventosResult.map((e) => {
          let evento = {
            id: e.atividadeID,
            title: e.assunto,
            status: e.status,
            start: moment(e.previsaoInicio).add(-3, 'hours').toDate(),
            end: moment(e.previsaoTermino).add(-3, 'hours').toDate(),
            cliente: e.nomeCliente,
            rastreador: e.numSerieRastreadorInstalado,
            placa: e.veiculoIdName,
            na: e.na,
            servico: e.servico,
            nomeTecnico: e.nomeTecnico
          };

          _eventos.push(evento);
        });
        setEventosAgenda(_eventos);
        setEventos(eventosResult);
        verificaEventosDoDia();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const inicioFimMes = () => {
    const inicio = momentTimezone()
      .startOf('month')
      .add(-3, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    const fim = momentTimezone()
      .endOf('month')
      .add(-3, 'hours')
      .format('YYYY-MM-DD HH:mm:ss');

    return { inicio, fim };
  };

  const loadCalendario = async () => {
    const { inicio, fim } = inicioFimMes();
    await consultaEventos(inicio, fim);
  };

  const loadEventosTotal = async () => {
    await consultaEventosTotal();
  };

  const myCustomMonthDateHeader = ({ label }) => {
    return (
      <div>
        {label}
      </div>
    );
  };
  useEffect(() => {
    if (atividadeServico?.nomeTecnico && tecnicosAprovados.length > 0) {
      const tecnicoEncontrado = tecnicosAprovados.find(
        (tecnico) => tecnico.label?.toLowerCase() === atividadeServico.nomeTecnico?.toLowerCase()
      );

      setTecnicoSelecionado(tecnicoEncontrado || null);
    }
  }, [atividadeServico, tecnicosAprovados]);

  class CustomToolbar extends Toolbar {
    render() {
      return (
        <div className="rbc-toolbar">
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.navigate('PREV')}>
              {'< Anterior'}
            </button>
            <button type="button" onClick={() => this.navigate('TODAY')}>
              {'Hoje'}
            </button>
            <button type="button" onClick={() => this.navigate('NEXT')}>
              {'Próximo >'}
            </button>
          </span>
          <span className="rbc-toolbar-label" style={mobile ? { width: '100%', margin: '10px 0' } : {}}>
            {this.props.label}
          </span>
        </div>
      );
    }
  }

  const handleInputChange = (event, newInputValue) => {
    const numericValue = newInputValue.replace(/[^0-9]/g, '');

    setInputValue(numericValue);
    debouncedBuscarNas(numericValue);
  };

  const handleOpenAgendamento = (na) => {
    const areaAtendimentoID = authService.getUserInfo().usuario.tz_area_atendimento_microsigaid;
    buscarDetalhesOS(na.numeroNA, areaAtendimentoID);
    setInputValue('');
    setSelectedNa(null);
  };

  return (

    <div>
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />

      {loading && <LinearProgressBlue style={{ marginBottom: 20 }} />}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={7} md={7}  >
          <h4 className={classes.acessoRapidoStyle}>Agenda</h4>
        </Grid>
        <Grid item xs={12} sm={5} md={5} style={{ marginTop: isMobile ? '1px' : '15px' }}>
          <div style={{ width: '100%', paddingLeft: 3, paddingRight: 3 }}>
            <Autocomplete
              fullWidth
              options={options}
              getOptionLabel={(option) => option.numeroNA || ''}
              value={selectedNa}
              onChange={(_, newValue) => {
                if (newValue && newValue.numeroNA) {
                  handleOpenAgendamento(newValue);
                }
              }}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              renderInput={(params) => (
                <TextField {...params} label="Pesquisar NA" variant="outlined" />
              )}
              noOptionsText={loadingSearch ? 'Buscando...' : (options.length === 0 && inputValue.trim().length >= 3 ? 'NA não localizada' : 'Digite 3 caracteres para localizar NA')}
            />
          </div>
        </Grid>

      </Grid>
      <Grid container spacing={2} style={{ marginTop: isMobile ? '25px' : '1px', }}>
        <Grid item lg={7} xs={12} >
          <Calendar
            localizer={localizer}
            events={eventosAgenda}
            defaultView="month"
            messages={messages}
            formats={formats}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventPropGetter}
            style={{ height: 500 }}
            onSelectSlot={abreAtividadesAgenda}
            selectable={true}
            onNavigate={loading ? () => { } : handleNavigate}
            components={{
              dateCellWrapper: (props) => (
                <EventWrapper {...props} onSelectSlot={abreAtividadesAgenda} />
              ),
              toolbar: CustomToolbar,
              month: {
                dateHeader: myCustomMonthDateHeader,
              },
            }}
          />
        </Grid>
        <Grid item lg={5} xs={12}>
          <DailyAgenda eventos={eventosAgendaDoDia} dataSelecionada={dataAgenda} loading={loading} />
        </Grid>
      </Grid>


      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Serviço Atrasado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Há {totalEventosAtrasados} eventos atrasados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="customized-dialog-title"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialogAgendamento(false);
          }
        }}
        open={openDialogAgendamento}
        style={{ marginTop: 35, marginBottom: 10 }}
      >
        <DialogTitle>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Agendamento {atividadeServico?.na || 'Não informado'}
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => setOpenDialogAgendamento(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {loadingDetalhes ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height: isMobile ? '100px' : '300px',
                width: isMobile ? '100%' : '500px',
              }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            atividadeServico && (
              <Grid container spacing={1}>
                {tecnicosAprovados.length > 0 && (
                  <>
                    <div style={{ marginBottom: '16px', width: '100%' }}>
                      <Grid item xs={12}>
                        <h3>Técnico responsável</h3>
                      </Grid>
                      {loading ? (
                        <Grid container justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Grid>
                      ) : (
                        <Select
                          options={tecnicosAprovados}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          value={tecnicoSelecionado}
                          onChange={handleChange}
                          isDisabled={loading}
                          isLoading={loading}
                          placeholder="Selecione um Técnico"
                        />
                      )}
                    </div>
                  </>
                )}
                <Grid item xs={12}>
                  <h3>Informações sobre a NA</h3>
                </Grid>

                <Grid item xs={6}>
                  <strong>NA:</strong> {atividadeServico.na || 'Não informado'}
                </Grid>
                <Grid item xs={6}>
                  <strong>Status:</strong> {atividadeServico.descStatusNA || 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Cliente:</strong> {atividadeServico.cliente || 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Solicitante:</strong> {atividadeServico.tz_nome_solicitante || 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Contato:</strong> {atividadeServico.tz_telefone_solicitante || 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Serviço:</strong> {atividadeServico.serviceIdName || 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Assunto:</strong> {atividadeServico?.assuntoAtividade}
                </Grid>
                <Grid item xs={6}>
                  <strong>Início:</strong>{' '}
                  {atividadeServico?.inicioAtividade
                    ? new Date(atividadeServico.inicioAtividade).toLocaleString('pt-BR')
                    : 'Não informado'}
                </Grid>
                <Grid item xs={6}>
                  <strong>Término:</strong>{' '}
                  {atividadeServico.terminoAtividade
                    ? new Date(atividadeServico.terminoAtividade).toLocaleString('pt-BR')
                    : 'Não finalizado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Previsão de Início:</strong>{' '}
                  {atividadeServico.data_inicio
                    ? new Date(atividadeServico.data_inicio).toLocaleString('pt-BR')
                    : 'Não informado'}
                </Grid>
                <Grid item xs={12}>
                  <strong>Previsão de Término:</strong>{' '}
                  {atividadeServico.data_fim
                    ? new Date(atividadeServico.data_fim).toLocaleString('pt-BR')
                    : 'Não finalizado'}
                </Grid>

                {atividadeServico.localAtendimento !== 'Ponto fixo' && (
                  <>
                    <Grid item xs={12}>
                      <h3>Informações de endereço</h3>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Logradouro:</strong> {atividadeServico.tz_Endereco_Rua || 'Não informado'}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Número:</strong> {atividadeServico.tz_Endereco_Numero || 'Não informado'}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>CEP:</strong> {atividadeServico.tz_endereco_cepIdName || 'Não informado'}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Bairro:</strong> {atividadeServico.tz_endereco_bairro || 'Não informado'}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Cidade:</strong> {atividadeServico.tz_endereco_cidadeIdName || 'Não informado'}
                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <strong>Estado:</strong> {atividadeServico.tz_endereco_EstadoIdName || 'Não informado'}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <strong>Complemento:</strong>{' '}
                      {atividadeServico.tz_endereco_complemento || 'Não informado'}
                    </Grid>
                  </>
                )}
              </Grid>
            )
          )}

        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            onClick={() => setOpenDialogAgendamento(false)}
            variant="contained"
            size="large"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}>
            Voltar
          </Button>

          <BlueButton
            onClick={() => handleDetalhesOrdemServico(atividadeServico)}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Detalhes
          </BlueButton>

        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={openDialogConfirmaInicioAtividade}
        style={{ marginTop: 45, marginBottom: 10 }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            setOpenDialogConfirmaInicioAtividade(false)
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={{ position: 'sticky', top: 0, zIndex: 1100, backgroundColor: 'white' }}>
          <Grid container alignItems="center">
            <Grid item xs={10} sm={11}>
              Iniciar Atividade de Serviço
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => setOpenDialogConfirmaInicioAtividade(false)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom style={{ textAlign: 'start', marginBottom: '16px' }}>
            Depois de iniciada, a atividade não poderá mais ser interrompida. Tem certeza de que deseja
            iniciar a atividade?
          </Typography>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setOpenDialogConfirmaInicioAtividade(false)}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', float: 'right', height: 55 }}
          >
            Fechar
          </Button>

          <BlueButton
            variant="contained"
            fullWidth
            onClick={handleIniciarAtividadeDeServico}
            style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
          >
            Confirmar
          </BlueButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EventCalendar;