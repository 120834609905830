import { Box, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircleOutline, HighlightOff, HourglassEmpty } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import { Alert, AlertTitle } from '@material-ui/lab';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Anotacao from '../atividade-servico/component/anotacao';
import ContraSenhaComponent from '../atividade-servico/component/contra-senha-component';
import SenhaSupervisorComponent from '../atividade-servico/component/senha-supervisor-component';
import CustomAlert from '../components/alert/CustomAlert';
import BlueButton from '../components/button/BlueButton';
import GreenButton from '../components/button/GreenButton';
import MenuComponent from '../components/menu/menu-component';
import BlueTextField from '../components/textfield/BlueTextField';
import authService from '../Services/auth/auth-service';
import estoqueService from '../Services/Estoque/estoque-service';
import ordemServicoServico from '../Services/OrdemServico/ordem-servico-servico';
import painelAtivacaoService from '../Services/PainelAtivacao/painel-ativacao-service';
import perfilTecnicoService from '../Services/perfilTecnico/perfil-tecnico-services';
import senhaSupervisorService from '../Services/SenhaSupervisor/senha-supervisor-service';
import vistoriaService from '../Services/Vistoria/vistoria-service';
import { statusVistoria } from '../util/statusVistoria';
import { ProdutosTable } from './components/os/produtos-table-component';
import { OSVistoriaPage } from './os-vistoria-page';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 10,
  },
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px'
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px'
  },
  title: {
    fontSize: 23,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  expansionSubtitle: {
    fontSize: 23,
    fontWeight: 'bold',
  },
  itemText: {
    fontSize: 16,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
}));

export default function OrdemServicoPage(props,) {
  const classes = useStyles();
  const { na } = useParams();
  const history = useHistory();
  const [os, setOS] = useState(null);
  const [vistoria, setVistoria] = useState(null);
  const [itensOS, setItensOS] = useState([]);
  const [estoqueItens, setEstoque] = useState([]);
  const [observacao, setObservacao] = useState(null);
  const [openDialogVistoria, setOpenDialogVistoria] = useState(false);
  const [, setOpenDialogContraSenha] = useState(false);
  const [, setLoad] = useState(false);
  const [, setLoadSave] = useState(false);
  const [loadVistoria, setLoadVistoria] = useState(false);
  const [dadosVistoria, setDadosVistoria] = useState(null);
  const [alertVistoriaAprovada, setAlertVistoriaAprovada] = useState(false);
  const [alerItensAprovada, setAlertItensAprovada] = useState(false);
  const [efeitoCarregando, setEfeitoCarregando] = useState(false);
  const [efeitoCarregandoAtualizarBase, setEfeitoCarregandoAtualizarBase] = useState(false);
  const [painelAtivacaoEquipamento, setPainelAtivacaoEquipamento] = useState(false);
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState('');
  const [tipoAlert, setTipoAlert] = useState('error');
  const [senhaSupervisor, setSenhaSupervisor] = useState('');
  const [mensagemSenhaNaoEncontrada, setMensagemSenhaNaoEncontrada] = useState('');
  const areaAtendimentoID = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;
  const [loading, setLoading] = useState(false);
  const classificacaoPrestador = authService?.getUserInfo()?.usuario?.classificacaoPrestador;
  const usuarioGestor = authService?.getUserInfo()?.usuario?.gestor;
  const [tecnicoResponsavel, setTecnicoResponsavel] = useState(null);


  const motivoCodigoMap = {
    '419400000': 'Apagado',
    '419400001': 'Display queimado',
    '419400002': 'Teclas falhando',
    '419400003': 'Não recebe comandos',
    '419400004': 'Mau uso',
    '419400005': 'Queimado',
    '419400006': 'Infiltração d\'água',
    '419400007': 'Não comunica',
    '419400008': 'Não aciona',
    '419400009': 'Não bloqueia',
    '419400010': 'Quebrado - Mau uso',
    '419400011': 'Senha supervisor'
  };

  const mapCodigoToMotivo = (motivo) => motivoCodigoMap[motivo] || null;

  useEffect(() => {
    if (usuarioGestor === true && (!na || na)) {
      history.push('/gestor');
    } else if (!na) {
      history.push('/agenda');
    }
  }, [na, usuarioGestor]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await consultaDetalheOS();
      await painelAtivacao();
      if (classificacaoPrestador !== 2) {
        await produtosEstoque();
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTecnicoResponsavel = async () => {
      try {
        if (os?.os) {
          const response = await perfilTecnicoService.buscarTecnicoPorOS(os.os);
          if (!response.error) {
            setTecnicoResponsavel(response);
          } else {
            console.error('Erro ao buscar técnico responsável:', response.message);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar técnico responsável:', error);
      }
    };

    fetchTecnicoResponsavel();
  }, [os?.os]);

  useEffect(() => {
    if (classificacaoPrestador !== 2) {
      if (os) getVistoria();
    }
  }, [os]);

  const painelAtivacao = async () => {
    const response = await painelAtivacaoService.painelAtivacao(na);

    if (response.error != true) {
      setPainelAtivacaoEquipamento(response.result)
    }
  }

  const buscaSenhaSupervisor = async (numeroContrato) => {
    const response = await senhaSupervisorService.buscaSenhaSupervisor(numeroContrato);
    if (!response.errorLogin) {
      if (response.error == false) {
        setSenhaSupervisor(response.dados)
      } else {
        setMensagemSenhaNaoEncontrada('Não foi possível encontrar a senha registrada nesse serial.')
      }
    }
  };

  const ativaBotaoAtualizarBase = () => {
    let disabled = true;

    if (classificacaoPrestador === 2) {
      if (os?.statusNa !== 'Fechado') {
        disabled = false;
      } else {
        disabled = true;
      }

      return disabled;
    } else {

      if (vistoria?.statusEntrada != 'Aceito') {
        setAlertVistoriaAprovada(true);
      } else {
        setAlertVistoriaAprovada(false);
      }

      const allItensAprovados = itensOS.every((i) => i.statusAprovacao === 2);

      if (!allItensAprovados || os?.status !== 'Concluido') {
        setAlertItensAprovada(true);
      } else {
        setAlertItensAprovada(false);
      }

      if (allItensAprovados && vistoria && vistoria.statusEntrada === 'Aceito' && os?.status === 'Concluido') {
        disabled = false;
      }
    }

    return disabled;
  };

  const disabledAtualizarBase = useMemo(() => {
    return ativaBotaoAtualizarBase();
  }, [itensOS, vistoria, classificacaoPrestador, os]);

  const produtosEstoque = async () => {
    const areaAtendimentoID = authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid;

    if (!areaAtendimentoID) {
      setMensagemAlert({ title: 'Atenção!', body: 'Sua sessão expirou. Faça login novamente.' });
      setTipoAlert('fail');
      authService.logout();
      window.location.href = '/';
      return;
    }
    try {
      const estoqueConsolidadoEServicosResult = await estoqueService.listarEstoqueConsolidadoEServicos(areaAtendimentoID);

      if (estoqueConsolidadoEServicosResult) {
        let estoqueAgrupado = {};

        estoqueConsolidadoEServicosResult.forEach((item) => {
          if (item.produtoNome && typeof item.produtoNome === 'string') {
            estoqueAgrupado[item.produtoNome] = {
              produtoId: item.produtoId,
              produtoNumero: item.produtoCodigo,
              nome: item.produtoNome,
              ehServico: item.ehServico,
              qtdDisponivel: item.quantidadeDisponivel > 0 ? item.quantidadeDisponivel : 1,
            };
          } else {
            console.warn('Produto ignorado devido a nome inválido:', item);
          }
        });

        const _estoqueItens = Object.values(estoqueAgrupado);
        setEstoque(_estoqueItens);
      } else {
        console.error('Erro: Retorno inesperado da API de estoque.');
      }
    } catch (error) {
      console.error('Erro ao buscar produtos em estoque:', error);
    }
  };

  const utilMask = {
    phone: function (value) {
      if (!value) return '';
      value = value.replace(/\D/g, '');
      if (value.length === 11) {
        return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
      } else if (value.length === 10) {
        return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
      } else {
        return value;
      }
    },

    cep: function (value) {
      if (!value) return '';
      value = value.replace(/\D/g, '');
      if (value.length === 8) {
        return value.replace(/(\d{5})(\d{3})/, '$1-$2');
      } else {
        return value;
      }
    }
  };

  const consultaDetalheOS = async () => {
    setLoad(true);

    let osResult = await ordemServicoServico.detalhesOS(na, areaAtendimentoID);

    let osOrcamento = '';
    if (classificacaoPrestador !== 2) {
      osOrcamento = await ordemServicoServico.listaOrcamentoOS(na, areaAtendimentoID)
    }

    if (osResult) {
      let annotations = osResult[0].annotations;

      let _os = {
        osID: osResult[0].tz_Ordem_ServicoId || '',
        os: osResult[0].os || '',
        activityId: osResult[0].activityId || '',
        cliente: osResult[0].cliente || '',
        clienteCpfCnpj: osResult[0].clienteCpfCnpj || '',
        clienteEmail: osResult[0].clienteEmail?.toLowerCase() || '',
        solicitante: osResult[0].tz_nome_solicitante || '',
        telefone_solicitante: utilMask.phone(osResult[0].tz_telefone_solicitante || ''),
        endereco: osResult[0].tz_Endereco_Rua || '',
        numero: osResult[0].tz_Endereco_Numero || '',
        cep: utilMask.cep(osResult[0].tz_endereco_cepIdName || ''),
        cidade: osResult[0].tz_endereco_cidadeIdName || '',
        estado: osResult[0].tz_endereco_EstadoIdName || '',
        tecnico: osResult[0].tz_nome_tecnico || '',
        placa: osResult[0].placa || '',
        referencia: osResult[0].tz_referencia || '',
        razaoStatus: osResult[0].razaoStatus || '',
        inicio: osResult[0].data_inicio,
        fim: osResult[0].data_fim,
        na: osResult[0].na || '',
        cor: osResult[0].cor || '',
        plataforma: osResult[0].tz_PlataformaIdName || '',
        rastreador: osResult[0].tz_RastreadorIdName || '',
        plano: osResult[0].tz_PlanoIdName || '',
        num_serie_contrato: osResult[0].tz_Numero_Serie_Contrato || '',
        num_serie_antena_contrato: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
        fim_garantia: osResult[0].tz_Data_Fim_Garantia,
        observacao: osResult[0].tz_Observacoes || '',
        status: osResult[0].descStatus || '',
        obsCliente: osResult[0].detalheCliente || '',
        bairro: osResult[0].tz_endereco_bairro || '',
        modificacao: osResult[0].modifiedOn || '',
        servico: osResult[0].serviceIdName || '',
        antena: osResult[0].tz_Numero_Serie_Antena_Contrato || '',
        clienteID: osResult[0].clienteID || '',
        id_area_atendimento:
          osResult[0].tz_area_atendimento_microsigaId || areaAtendimentoID,
        anotacao: annotations && annotations.length > 0 ? annotations[0] : {},
        cenarioVenda: osResult[0].tz_Cenario_Venda || '',
        marca: osResult[0].marca || '',
        marcaID: osResult[0].marcaid || '',
        modelo: osResult[0].modelo || '',
        modeloID: osResult[0].modeloid || '',
        tipoServico: osResult[0].tipoServico || '',
        localAtendimento: osResult[0].localAtendimento || '',
        tipoServicoAgendamento: osResult[0].tipoServicoAgendamento || '',
        statusNa: osResult[0].descStatusNA || ''
      };

      if (osResult[0].tipoServicoAgendamento === 'MANUTENCAO') {
        buscaSenhaSupervisor(osResult[0].tz_Numero_Serie_Contrato);
      }

      setOS(_os);
      setObservacao(
        _os.observacao
          ? _os.observacao
          : 'Técnico - \nSérie - \nPlaca - \nSatélite - \nTem carreta sim ou não? \nPlaca da carreta -'
      );

      if (classificacaoPrestador !== 2) {
        if (osOrcamento) {
          let _itensOS = [];
          let contador = 1;
          osOrcamento.map((o) => {
            let item = {
              id: contador,
              itemID: o.tz_item_ordem_servicoId,
              produtoId: o.tz_ItemId,
              numeroProduto: o.produtoNumero,
              produto: o.produtoNome,
              quantidade: o.quantidade,
              descStatus: o.descStatus_aprovacao,
              statusAprovacao: o.status_aprovacao,
              motivoOrcamento: mapCodigoToMotivo(o.tz_motivo_orcamento),
              baseDeTroca: o.base_de_troca,
              codItemBaseDeTroca: o.cod_item_base_troca,
              nomeItemBaseDeTroca: o.nome_item_base_troca,
              serialItemBaseDeTroca: o.serial_item_base_troca,
              itemDeEstoque: o.item_de_estoque,
            };

            _itensOS.push(item);
            contador = contador + 1;

          });

          setItensOS(_itensOS);
        }
      }
    } else if (osResult === undefined) {
      if (usuarioGestor === true) {
        history.push('/gestor');
      } else {
        history.push('/agenda');
      }
    }
    setLoad(false);
  };

  async function getVistoria() {
    try {
      setLoadVistoria(true);
      const { osID, activityId } = os;

      const responseVistoria = await vistoriaService.consultaVistoria(
        activityId,
        osID
      );

      setVistoria(responseVistoria);

      if (responseVistoria?.error) {
        setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status da vistoria.' });
        setTipoAlert('error');
        setAbrirAlert(true);
      } else {
        if (responseVistoria) setDadosVistoria(responseVistoria);
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível obter o status da vistoria.' });
      setTipoAlert('error');
      setAbrirAlert(true);
    } finally {
      setLoadVistoria(false);
    }
  }

  const handleSalvarOS = async () => {
    setEfeitoCarregando(true);
    const salvou = await salvarOS();
    if (salvou) {
      setTimeout(() => {
        setEfeitoCarregando(false);
        window.location.reload();
      }, 5000);
    }
    setEfeitoCarregando(false);
  };

  const handleCloseDialogContraSenha = () => setOpenDialogContraSenha(false);

  const handleOpenDialogVistoria = () => setOpenDialogVistoria(true);

  const handleCloseDialogVistoria = () => setOpenDialogVistoria(false);

  const addProduto = async (produtoID, produto, quantidade, codProduto, ehServico, itemDeEstoque, baseDeTroca, codItemBaseDeTroca, nomeItemBaseDeTroca, serialProdutoRemanufatura) => {
    try {
      let produtoOrdemServico = {
        OrdemId: os.osID,
        produtoId: produtoID.trim(),
        produtoNome: produto,
        quantidade: parseInt(quantidade),
        TecnicoAddItem: authService?.getUserInfo()?.usuario?.login,
        UsuarioAprovacaoPortal: '',
        StatusItemOrdem: '1',
        CodProduto: codProduto,
        tz_area_atendimento_microsigaid:
          authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid,
        na: os.na,
        os: os.os,
        idUsuario: authService?.getUserInfo()?.usuario?.id,
        numSerieContrato: os?.num_serie_contrato,
        numSerieAntenaContrato: os?.antena,
        ehServico: ehServico,
        ItemDeEstoque: itemDeEstoque,
        BaseDeTroca: baseDeTroca,
        CodigoItemBaseDeTroca: codItemBaseDeTroca,
        NomeItemBaseDeTroca: nomeItemBaseDeTroca,
        SerialItemBaseDeTroca: serialProdutoRemanufatura
      };

      let resultAddProduto = await ordemServicoServico.addProduto(
        JSON.stringify(produtoOrdemServico)
      );

      if (resultAddProduto.error === true) {
        setMensagemAlert({ title: 'Erro!', body: resultAddProduto.message });
        setTipoAlert('error');
        setAbrirAlert(true);

        return false;
      }

      setMensagemAlert({ title: 'Sucesso!', body: 'Produto adicionado com sucesso.' });
      setTipoAlert('success');
      setAbrirAlert(true);
      return true;

    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao executar operação.' });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }
  };

  const alteraProduto = async (
    produtoId,
    produto,
    quantidade,
    codProduto,
    itemOrdemID,
    ehServico,
    itemDeEstoque,
    baseDeTroca, codItemBaseDeTroca, nomeItemBaseDeTroca, serialProdutoRemanufatura
  ) => {
    try {
      let produtoOrdemServico = {
        OrdemId: os.osID,
        produtoId: produtoId,
        produtoNome: produto,
        quantidade: parseInt(quantidade),
        TecnicoAddItem: authService?.getUserInfo()?.usuario?.login,
        UsuarioAprovacaoPortal: '',
        StatusItemOrdem: '1',
        CodProduto: codProduto,
        tz_area_atendimento_microsigaid:
          authService?.getUserInfo()?.usuario?.tz_area_atendimento_microsigaid,
        na: os.na,
        os: '',
        idUsuario: authService?.getUserInfo()?.usuario?.id,
        estoqueVirtual: true,
        ItemOrdemId: itemOrdemID,
        numSerieContrato: os?.num_serie_contrato,
        numSerieAntenaContrato: os?.antena,
        ehServico: ehServico,
        ItemDeEstoque: itemDeEstoque,
        BaseDeTroca: baseDeTroca,
        CodigoItemBaseDeTroca: codItemBaseDeTroca,
        NomeItemBaseDeTroca: nomeItemBaseDeTroca,
        SerialItemBaseDeTroca: serialProdutoRemanufatura
      };

      let resultAltProduto = await ordemServicoServico.alteraProduto(
        JSON.stringify(produtoOrdemServico),
        itemOrdemID
      );

      if (resultAltProduto.error === true) {
        setMensagemAlert({ title: 'Erro!', body: resultAltProduto.message });
        setTipoAlert('error');
        setAbrirAlert(true);

        return false;
      }

      return true;
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Erro ao executar operação.' });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }
  };

  const salvarOS = async () => {
    setLoadSave(true);

    let salvarOBSResult = await ordemServicoServico.observacao(
      JSON.stringify({
        Observacao: observacao,
        OrdemServicoID: os.osID,
      })
    );

    setLoadSave(false);

    if (salvarOBSResult.error) {
      setMensagemAlert({ title: 'Erro!', body: salvarOBSResult.message });
      setTipoAlert('error');
      setAbrirAlert(true);

      return false;
    }

    setMensagemAlert({ title: 'Sucesso!', body: 'As alterações foram salvas.' });
    setTipoAlert('success');
    setAbrirAlert(true);

    return true;
  };

  const handleFecharOS = async () => {
    setEfeitoCarregandoAtualizarBase(true);

    if (classificacaoPrestador !== 2) {
      const salvou = await salvarOS();
      setEfeitoCarregandoAtualizarBase(false);
      if (!salvou) return;
    }

    const allItensAprovados = itensOS.every((i) => i.statusAprovacao === 2);

    props.history.push({
      pathname: `/atividadeservico/${os.na}`,
      state: {
        statusEntrada: vistoria?.statusEntrada,
        ItensAprovadosOrcamento: allItensAprovados === true && os?.status === 'Concluido' ? true : false
      },
    });
  };

  const renderVistoriaStatusAlert = (status, title) => {
    const alertStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' };

    switch (status) {
      case 'Aceito':
        return (
          <Alert severity="success" icon={<CheckCircleOutline style={{ color: 'green' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aceito
          </Alert>
        );

      case 'Recusado':
        return (
          <Alert severity="error" icon={<HighlightOff style={{ color: 'red' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Recusado
          </Alert>
        );

      case 'Aguardando aprovação':
        return (
          <Alert severity="warning" icon={<HourglassBottomIcon style={{ color: 'orange' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Aguardando aprovação
          </Alert>
        );

      default:
        return (
          <Alert severity="info" icon={<HourglassEmpty style={{ color: '#1976d2' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Não Enviado
          </Alert>
        );
    }
  };

  const renderOSStatusAlert = (status, title) => {
    const alertStyle = { display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' };

    switch (status) {
      case 'Concluído':
        return (
          <Alert severity="success" icon={<CheckCircleOutline style={{ color: 'green' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Fechada
          </Alert>
        );

      default:
        return (
          <Alert severity="warning" icon={<HourglassBottomIcon style={{ color: 'orange' }} />} style={alertStyle}>
            <AlertTitle>{title}</AlertTitle>
            Em Aberto
          </Alert>
        );
    }
  };

  return !openDialogVistoria ? (
    <>
      <MenuComponent />
      <CustomAlert
        open={abrirAlert}
        message={mensagemAlert}
        type={tipoAlert}
        onClose={() => setAbrirAlert(false)}
      />
      <div style={{ margin: 30 }}>
        <h3 className={classes.tituloStyle}>Ordem de Serviço</h3>
        <h4 className={classes.subtituloStyle}>
          <span
            onClick={() => window.location.href = '/agenda'}
            className={classes.linkStyle}
            style={{ cursor: 'pointer' }}
          >
            Home
          </span> {'>'} Ordem de Serviço
        </h4>

        <Paper className={classes.paper}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <span className={classes.title}>Detalhes do Agendamento</span>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '16px',
                      borderRadius: '8px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>NA:</strong> {os?.na}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Serviço:</strong> {os?.servico}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Técnico Responsável:</strong> {tecnicoResponsavel ? tecnicoResponsavel.nome : 'Carregando...'}
                      </Grid>
                      <Grid item xs={12} sm={6} className={classes.itemText}>
                        <strong>Razão Status:</strong> {os?.razaoStatus}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Cliente:</strong> {os?.cliente}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Data Inicial:</strong> {os?.inicio ? moment(os?.inicio).format('DD/MM/YYYY HH:mm:ss') : ''}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Data Final:</strong> {os?.fim ? moment(os?.fim).format('DD/MM/YYYY HH:mm:ss') : ''}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>

                {os?.localAtendimento !== 'Ponto fixo' ? (
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '16px',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Grid container spacing={1}>
                        {os?.localAtendimento !== 'Ponto fixo' && (
                          <>
                            <Grid item xs={12} className={classes.itemText}>
                              <strong>Solicitante:</strong> {os?.solicitante}
                            </Grid>
                            <Grid item xs={12} className={classes.itemText}>
                              <strong>Telefone do Solicitante:</strong> {os?.telefone_solicitante}
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Endereço:</strong> {os?.endereco}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Número:</strong> {os?.numero ? os?.numero : 'S/N'}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Bairro:</strong> {os?.bairro}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>CEP:</strong> {os?.cep}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Cidade/Estado:</strong> {os?.cidade} {os?.cidade && os?.estado && ' - '} {os?.estado}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Referência:</strong> {os?.referencia}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Paper
                      elevation={3}
                      style={{
                        padding: '16px',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Solicitante:</strong> {os?.solicitante}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Telefone do Solicitante:</strong> {os?.telefone_solicitante}
                        </Grid>
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Local atendimento:</strong> {os?.localAtendimento}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Paper
                    elevation={3}
                    style={{
                      padding: '16px',
                      borderRadius: '8px',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Placa do veículo:</strong> {os?.placa}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Plataforma:</strong> {os?.plataforma}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Rastreador:</strong> {os?.rastreador}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Plano:</strong> {os?.plano}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Número de Série do Contrato:</strong> {os?.num_serie_contrato}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText} hidden={os?.num_serie_antena_contrato == ''}>
                        <strong>Número de Série da Antena do Contrato:</strong> {os?.num_serie_antena_contrato}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Cenário de Venda:</strong> {os?.cenarioVenda}
                      </Grid>
                      <Grid item xs={12} className={classes.itemText}>
                        <strong>Fim Garantia:</strong> {os?.fim_garantia ? os?.fim_garantia : ''}
                      </Grid>
                      {painelAtivacaoEquipamento && (
                        <Grid item xs={12} className={classes.itemText}>
                          <strong>Status de Ativação do Equipamento:</strong> {painelAtivacaoEquipamento.status}
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>

                {classificacaoPrestador !== 2 && (
                  <>
                    <Grid item xs={12}>
                      <Paper
                        elevation={3}
                        style={{
                          padding: '16px',
                          borderRadius: '8px',
                          backgroundColor: '#fff',
                        }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12} className={classes.itemText}>
                            <strong>Status OS: </strong> {os?.status === 'Concluido' ? 'Concluído' : os?.status}
                          </Grid>
                          <Grid item xs={12} className={classes.itemText}>
                            <strong>Número OS:</strong> {os?.os}
                          </Grid>
                          <Grid item xs={12} className={classes.itemText} style={{ marginBottom: 10 }}>
                            <strong>Observação do Cliente:</strong> {os?.obsCliente}
                          </Grid>
                          <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                            <Grid item xs={12}>
                              <BlueTextField
                                name="txtobs"
                                fullWidth
                                label="Observação"
                                onChange={(event) => setObservacao(event.target.value)}
                                value={observacao}
                                disabled={os?.statusNa === 'Fechado'}
                                multiline
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                              <BlueButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                                startIcon={efeitoCarregando ? <CircularProgress size={20} /> : <SaveIcon />}
                                onClick={handleSalvarOS}
                                disabled={efeitoCarregando || os?.statusNa === 'Fechado'}
                                style={{ height: 55, marginBottom: 5 }}
                              >
                                <span style={{ fontSize: '0.7rem' }}>
                                  {efeitoCarregando ? 'Salvando... ' : ' Salvar Observação'}
                                </span>
                              </BlueButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
        </Paper>

        {!loading && (
          <>
            {os?.statusNa !== 'Fechado' && (
              <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid item xs={12} className={classes.expansionSubtitle}>
                      Contrassenha
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <ContraSenhaComponent os={os} handleBack={handleCloseDialogContraSenha} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            )}

            {os?.tipoServicoAgendamento == 'MANUTENCAO' && os?.statusNa !== 'Fechado' && (
              <>
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <ExpansionPanel style={{ marginBottom: 20, borderRadius: 10 }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item xs={12} className={classes.expansionSubtitle}>
                        Senha do Supervisor
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <SenhaSupervisorComponent
                        usuario={os?.cliente}
                        numeroEquipamento={os?.num_serie_contrato}
                        dadosSenhaSupervisor={senhaSupervisor}
                        mensagem={mensagemSenhaNaoEncontrada}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </>
            )}

            {classificacaoPrestador !== 2 && (
              <>
                <Paper className={classes.paper}>
                  <span className={classes.title}>Status da Vistoria</span>
                  {loadVistoria ? (
                    <Grid container alignItems="center" justifyContent="center">
                      <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
                    </Grid>
                  ) : (
                    <Grid container style={{ marginTop: 5, marginBottom: 10 }} spacing={2}>
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} md={6} style={{ padding: 16 }}>
                          {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'entrada'), 'Entrada')}
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: 16 }}>
                          {renderVistoriaStatusAlert(statusVistoria(dadosVistoria, 'saida'), 'Saída')}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Paper>

                <Paper className={classes.paper}>
                  <span className={classes.title}>Status da OS</span>
                  <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={12} style={{ padding: 16 }}>
                      {renderOSStatusAlert(os?.status === 'Concluido' ? 'Concluído' : os?.status)}
                    </Grid>
                  </Grid>
                </Paper>

                {vistoria?.statusEntrada && vistoria.statusEntrada !== 'Não Enviado' ? (
                  <Paper className={classes.paper}>
                    <span className={classes.title}>Orçamento</span>
                    {loadVistoria ? (
                      <Grid container alignItems="center" justifyContent="center">
                        <CircularProgress color="#209BDE" style={{ color: '#209BDE' }} />
                      </Grid>
                    ) : (
                      <Grid container style={{ marginTop: 5 }} spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                          <ProdutosTable
                            addProduto={addProduto}
                            na={na}
                            alteraProduto={alteraProduto}
                            consultaDetalheOS={consultaDetalheOS}
                            estoqueItens={estoqueItens}
                            itensOS={itensOS}
                            os={os}
                            consultaProdutosEstoque={produtosEstoque}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Paper>
                ) : (
                  <Paper className={classes.paper}>
                    <span className={classes.title}>Orçamento</span>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                      <Grid item xs={12} md={12} style={{ padding: 16 }}>
                        <Alert severity="warning">
                          É necessário enviar a vistoria de entrada antes de acessar o orçamento.
                        </Alert>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </>
            )}

            {os && classificacaoPrestador === 2 && (
              <>
                <Paper className={classes.paper}>
                  <Grid item xs={12} className={classes.expansionSubtitle}>
                    Anotações
                  </Grid>
                  <Box style={{ padding: 20 }}>
                    <Anotacao ordemServico={os} StatusNA={os?.statusNa} />
                  </Box>
                </Paper>
              </>
            )}

            <Box
              sx={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}
              container
              style={{ marginTop: 10 }}
            >
              {(alertVistoriaAprovada || alerItensAprovada) && (
                <Alert severity="warning" style={{ marginBottom: 15 }}>
                  {alertVistoriaAprovada && alerItensAprovada && (
                    <div>Botão de próxima página ficará disponível após Itens Aprovados, OS Fechada e Vistoria de Entrada Aprovada.</div>
                  )}
                  {alertVistoriaAprovada && !alerItensAprovada && (
                    <div>Botão de próxima página ficará disponível após Vistoria de Entrada Aprovada.</div>
                  )}
                  {!alertVistoriaAprovada && alerItensAprovada && (
                    <div>Botão de próxima página ficará disponível após Itens Aprovados e OS Fechada.</div>
                  )}
                </Alert>
              )}
            </Box>

            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              {classificacaoPrestador !== 2 && (
                <Grid item xs={12} sm={3} md={2}>
                  <BlueButton
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AssignmentIcon />}
                    onClick={handleOpenDialogVistoria}
                    style={{ height: 55, marginBottom: 10 }}
                  >
                    <span style={{ fontSize: '0.7rem' }}>{statusVistoria(dadosVistoria, 'entrada') == 'Aceito' ? 'Visualizar Vistoria' : 'Vistoria de Entrada'}</span>
                  </BlueButton>
                </Grid>
              )}

              <Grid item xs={12} sm={3} md={2}>
                <Tooltip
                  title={
                    classificacaoPrestador === 2 && os?.statusNa === 'Fechado'
                      ? 'Não é possível prosseguir pois a NA já foi concluída.'
                      : 'Prosseguir para finalização da NA.'
                  }
                >
                  <span> { }
                    <GreenButton
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={efeitoCarregandoAtualizarBase ? <CircularProgress size={20} /> : <NavigateNextIcon />}
                      onClick={handleFecharOS}
                      disabled={disabledAtualizarBase || efeitoCarregandoAtualizarBase}
                      style={{ height: 55, marginBottom: 10 }}
                    >
                      <span style={{ fontSize: '0.7rem' }}>
                        {efeitoCarregandoAtualizarBase ? 'PROSSEGUINDO... ' : '  PRÓXIMA PÁGINA'}
                      </span>
                    </GreenButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={3} md={2}>
                <BlueButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    window.location.reload();
                  }}
                  startIcon={<RefreshIcon />}
                  style={{ height: 55, marginBottom: 10 }}
                >
                  <span style={{ fontSize: '0.7rem' }}>RECARREGAR PÁGINA</span>
                </BlueButton>
              </Grid>
            </Grid>
          </>
        )}
      </div >
    </>
  ) : (
    <>
      <MenuComponent />
      <OSVistoriaPage
        ordemServico={os}
        handleBack={handleCloseDialogVistoria}
        itensOS={itensOS}
        tipoVistoria="entrada"
      />
    </>
  );
}
