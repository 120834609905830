import {
  Paper, Grid, TextField,  Typography, Box,
  useMediaQuery, CircularProgress, IconButton, InputAdornment,
  Dialog, DialogContent, DialogActions, DialogTitle, Button
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuComponent from '../../components/menu/menu-component';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import authService from '../../Services/auth/auth-service';
import CustomAlert from '../../components/alert/CustomAlert';
import CustomTablePagination from '../../components/pagination/pagination-component.js';
import NotificacaoService from '../../Services/notificacoes/notificacoes-tecnico-service';
import debounce from 'lodash/debounce';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlueButton from '../../components/button/BlueButton';
import Select from 'react-select';
import React, { useEffect, useState, useCallback } from 'react';

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 15px',
    borderRadius: 10,
  },
  tituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '22px',
    textAlign: 'start',
    padding: '0 0px',
    marginBottom: '5px',
  },
  subtituloStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: 'inherit',
    textAlign: 'start',
    padding: '0 0px',
    marginTop: '0px',
  },
  mensagemStyle: {
    fontFamily: 'Mont, sans-serif',
    color: '#209BDE',
    fontSize: '18px',
    textAlign: 'start',
    padding: '0 0px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}));

const NotificacoesPage = () => {
  const classes = useStyles();
  const [notificacoes, setNotificacoes] = useState([]);
  const [totalNotificacoes, setTotalNotificacoes] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);
  const [pesquisa, setPesquisa] = useState('');
  const [abrirAlert, setAbrirAlert] = useState(false);
  const [mensagemAlert, setMensagemAlert] = useState({ title: '', body: '' });
  const [tipoAlert, setTipoAlert] = useState('');
  const [carregandoTable, setCarregandoTable] = useState(true);
  const mobile = useMediaQuery(useTheme().breakpoints.down(800));
  const [descricaoNotificacao, setDescricaoNotificacao] = useState('');
  const [abrirModalVisualizarDescricao, setAbrirModalVisualizarDescricao] = useState(false);
  const [, setPermissao] = useState(0);
  const [loading, setLoading] = useState(true);
  const [carregandoNotificacao, setCarregandoNotificacao] = useState({});
  const [status, setStatus] = useState('todas');
  const [temPermissao, setTemPermissao] = useState(true);

  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => permissao.permissoesLista?.includes('cod_notificacoes'));
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
      console.log('Técnico secundário sem permissão para configurar.');
    }
  
  }, []);

  const abrirModalDescricao = (idNotificacao, descricaoNotificacao, statusVisualizacao) => {
    setDescricaoNotificacao(descricaoNotificacao);
    setAbrirModalVisualizarDescricao(true);
  
    if (!statusVisualizacao && idNotificacao) {
      notificacaoLida(idNotificacao); 
    }
  };

  const fecharModalVisualizarDescricao = () => {
    setAbrirModalVisualizarDescricao(false);
  }
  const statusOptions = [
    { value: 'todas', label: 'Todas' },
    { value: 'nao_visualizadas', label: 'Não Visualizadas' },
    { value: 'visualizadas', label: 'Visualizadas' },
  ];


  const buscarNotificacoes = async (page = 0, pageSize = 8, pesquisa = '', status = 'todas') => {
    setCarregandoTable(true);
    const dados = {
      Pagina: page,
      Limite: pageSize,
      Pesquisa: pesquisa,
      Status: status,
    };
  
    const data = await NotificacaoService.listarNotificacoesVisaoTecnico(authService.getUserInfo().usuario.id, dados);
  
    if (!data.error) {
      setNotificacoes(data.dados);
      setTotalNotificacoes(data.total);
    } else {
      setNotificacoes([]);
      setTotalNotificacoes(0);
    }
  
    setCarregandoTable(false);
  };

  const notificacaoLida = async (idNotificacao) => {
    try {
      setCarregandoNotificacao((prev) => ({ ...prev, [idNotificacao]: true }));
  
      const response = await NotificacaoService.visualizarNotificacao([idNotificacao]);
  
      if (response.error) {
        setMensagemAlert({ title: 'Atenção!', body: response.message });
        setTipoAlert('fail');
        setAbrirAlert(true);
      } else {
        setNotificacoes((prevNotificacoes) =>
          prevNotificacoes.map((notificacao) =>
            notificacao.id === idNotificacao
              ? { ...notificacao, noticacaoLida: true }
              : notificacao
          )
        );
      }
    } catch (error) {
      setMensagemAlert({ title: 'Erro!', body: 'Não foi possível atualizar o status da notificação.' });
      setTipoAlert('fail');
      setAbrirAlert(true);
    } finally {
      setCarregandoNotificacao((prev) => ({ ...prev, [idNotificacao]: false }));
    }
  };
  

  const mudarPagina = (event, newPage) => {
    setPage(newPage);
    buscarNotificacoes(newPage, pageSize, pesquisa, status);
  };

  const mudarTamanhoPagina = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
    setPage(0);
    buscarNotificacoes(0, newPageSize, pesquisa, status);
  };

  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0);
      buscarNotificacoes(0, pageSize, nome, status);
    }, 700),
    [pageSize, status]
  );


  const pesquisarDados = (event) => {
    const nome = event.target.value;

    if (nome !== pesquisa) {
      setPesquisa(nome);
      delayPesquisa(nome);
    }
  };

  const limparPesquisa = () => {
    setPesquisa('');
    setPage(0)
    buscarNotificacoes(0, pageSize, '');
  };

  const renderStatusVisualizacao = (notificacao) => {
    const isLoading = carregandoNotificacao[notificacao.id];
    const isVisualizada = notificacao.noticacaoLida;
    const bgColor = isVisualizada ? 'rgba(3, 201, 169, 1)' : 'rgba(255, 99, 132, 1)';
  
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          width: '100%',
          height: 44,
          marginTop: '10px',
          borderRadius: 15,
          backgroundColor: bgColor,
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} style={{ color: 'white' }} />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isVisualizada ? 'Visualizada' : 'Não visualizada'}
          </div>
        )}
      </div>
    );
  };
  
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setPermissao(authService.getUserInfo().usuario.gestor);
      await buscarNotificacoes(page, pageSize, pesquisa, status);
      setLoading(false);
    };

    fetchData();
  }, [mobile]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }
  const mudarStatus = (selectedOption) => {
    setStatus(selectedOption.value);
    setPage(0);
    buscarNotificacoes(0, pageSize, pesquisa, selectedOption.value);
  };
  
  return (
    <>
      {temPermissao ? (
          <>
            <MenuComponent />

            <CustomAlert
              open={abrirAlert}
              message={mensagemAlert}
              type={tipoAlert}
              onClose={() => setAbrirAlert(false)}
            />

            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Notificações</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => {
                    const userInfo = authService.getUserInfo();
                    if (userInfo.usuario.gestor) {
                      window.location.assign('/gestor');
                    } else if (userInfo.usuario.revenda) {
                      window.location.assign('/revenda');
                    } else {
                      window.location.assign('/agenda');
                    }
                  }}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Notificações
              </h4>

              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5} md={5}></Grid>
                  <Grid item xs={12} sm={5} md={2}>
                    <Select
                      options={statusOptions}
                      value={statusOptions.find((option) => option.value === status)}
                      onChange={mudarStatus}
                      placeholder="Filtrar por status"
                      isClearable={false}
                      styles={{
                        control: (provided) => ({ ...provided, height: 55, backgroundColor: '#EFF2F8' }),
                        menu: (provided) => ({ ...provided, backgroundColor: '#EFF2F8' }),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5} md={5}>
                    <TextField
                      label="Pesquisar"
                      variant="outlined"
                      value={pesquisa}
                      onChange={pesquisarDados}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {pesquisa ? (
                              <IconButton onClick={limparPesquisa}>
                                <ClearIcon />
                              </IconButton>
                            ) : (
                              <SearchIcon />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>

              <Dialog
                aria-labelledby="customized-dialog-title"
                open={abrirModalVisualizarDescricao}
                className="custom-dialog"
                style={{ marginTop: 35, marginBottom: 10 }}
                onClose={(event, reason) => {
                  if (reason !== 'backdropClick') {
                    fecharModalVisualizarDescricao();
                  }
                }}
                maxWidth="xs" 
                fullWidth
                
              >
                <DialogTitle>
                  <Grid container alignItems="center">
                    <Grid item xs={10} sm={11}>
                      Descrição da Notificação
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <IconButton onClick={fecharModalVisualizarDescricao}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent 
                dividers
                style={{ overflowY: 'hidden' }} 
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{
                        textAlign: 'justify',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <strong>Texto: </strong>{descricaoNotificacao}
                    </Typography>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={fecharModalVisualizarDescricao}
                    style={{ marginBottom: '10px', marginTop: '10px', width: '40%', height: 55 }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>

              <Paper className={classes.paper}>
                {carregandoTable ? (
                  <div className={classes.loadingContainer}>
                    <CircularProgress />
                  </div>
                ) : notificacoes.length === 0 ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: 15 }}>
                      Não há notificações.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid container spacing={1} alignItems="stretch">
                    {notificacoes.map((notificacao) => (
                      <Grid item xs={12} sm={6} md={3} key={notificacao.id}>
                        <Paper
                          style={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            minHeight: '250px',
                            height: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                          }}
                        >
                          <Box padding={1} style={{ flexGrow: 1 }}>
                            <Typography component="div" variant="body2" style={{ wordBreak: 'break-word' }}>
                            <strong>Título: </strong> {notificacao.tituloNotificacao}
                            </Typography>
                            <Typography component="div" variant="body2" style={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                              <strong>Texto: </strong>{notificacao.textoNotificacao.length > 40 ? `${notificacao.textoNotificacao.substring(0, 40)}...` : notificacao.textoNotificacao}
                            </Typography>
                            <Typography component="div" variant="body2">
                              <strong>Data de Notificação:</strong>{' '} {notificacao.dataCadastro ? new Date(notificacao.dataCadastro).toLocaleString() : ''}
                            </Typography>
                          </Box>

                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                              <Typography component="div" variant="body2">
                                {renderStatusVisualizacao(notificacao)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 5 }}>
                              <BlueButton
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                style={{ height: 55 }}
                                startIcon={<VisibilityIcon />}
                                onClick={() =>
                                  abrirModalDescricao(notificacao.id, notificacao.textoNotificacao, notificacao.noticacaoLida)
                                }
                              >
                                <span style={{ fontSize: '0.7rem' }}>Visualizar Descrição</span>
                              </BlueButton>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                )}
                <Grid container justifyContent="center" alignItems="center" spacing={1} style={{ marginTop: 10 }}>
                  <Grid item>
                    {notificacoes.length > 0 && (
                      <CustomTablePagination
                        count={totalNotificacoes}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={mudarPagina}
                        quantidadeItens={totalNotificacoes}
                        onRowsPerPageChange={mudarTamanhoPagina}
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div >
          </>
        ) : (
          <>
            <MenuComponent />
            <div style={{ margin: 30 }}>
              <h3 className={classes.tituloStyle}>Notificações</h3>
              <h4 className={classes.subtituloStyle}>
                <span
                  onClick={() => (window.location.href = '/agenda')}
                  className={classes.linkStyle}
                  style={{ cursor: 'pointer' }}
                >
                  Home
                </span>{' '}
                {'>'} Notificações
              </h4>

              <Paper className={classes.paper}>
                <Grid container justifyContent="center">
                  <h2 className={classes.mensagemStyle} style={{ padding: 30 }}>
                    Você não tem permissão para acessar esta página!
                  </h2>
                </Grid>
              </Paper>
            </div>
          </>
        )
      }
    </>
  )
};

export default NotificacoesPage;
