import {
	Box,
	CircularProgress,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Typography
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import GetAppIcon from '@material-ui/icons/GetApp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@mui/material/InputAdornment'
import React, { useCallback, useEffect, useState } from 'react'
import CustomAlert from '../components/alert/CustomAlert'
import BlueButton from '../components/button/BlueButton'
import MenuComponent from '../components/menu/menu-component'
import authService from '../Services/auth/auth-service'
import documentoService from '../Services/documentos/documentos-service'
import CustomTablePagination from '../components/pagination/pagination-component'
import debounce from 'lodash.debounce'

import { useMediaQuery, useTheme } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
	searchIcon: {
		marginRight: 4
	},
	searchInput: {
		flex: 1,
		padding: '8px',
		fontSize: 18,
		width: '100%'
	},
	search: {
		marginBottom: 40,
		float: 'right',
		display: 'flex',
		alignItems: 'center',
		width: '40%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	paper: {
		borderRadius: 10,
		display: 'flex',
		padding: '15px 20px 15px',
		overflow: 'hidden',
		flexDirection: 'column'
	},
	tituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px',
		marginBottom: '5px'
	},
	mensagemStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '22px',
		textAlign: 'start',
		padding: '0 0px'
	},
	mensagemDetalhe: {
		fontFamily: 'Mont, sans-serif',
		color: '#209BDE',
		fontSize: '13px',
		textAlign: 'center',
		padding: '0 0px'
	},
	subtituloStyle: {
		fontFamily: 'Mont, sans-serif',
		color: '#7F7F7F',
		fontSize: '16px',
		fontWeight: 'inherit',
		textAlign: 'start',
		padding: '0 0px',
		marginTop: '0px'
	},
	infos: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh'
	},

	item: {
		marginBottom: 30
	},
	avatar: {
		padding: 6,
		borderRadius: 50,
		backgroundColor: theme.palette.grey[500],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginRight: 5
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	categoryTitle: {
		marginTop: 20,
		marginBottom: 10,
		fontWeight: 'bold'
	}
}))

export default function ManuaisPage() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [, setPermissaoTCL] = useState(0);
	const [abrirAlert, setAbrirAlert] = useState(false);
	const [mensagemAlert] = useState('');
	const [tipoAlert] = useState('error');
	const [efeitoCarregando, setEfeitoCarregando] = useState({});
	const [documentosAgrupados, setDocumentosAgrupados] = useState({});
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(8);
	const [totalDados, setTotalDados] = useState(0);
	const [pesquisa, setPesquisa] = useState('');
	const [temPermissao, setTemPermissao] = useState(true);

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	useEffect(() => {
		buscarDocumentos()
		setPermissaoTCL(authService.getUserInfo().usuario.classificacaoPrestador)
	}, [])

	const buscarDocumentos = async (pesquisa = '') => {
    setLoading(true)
    try {
      const DocumentosLista = await documentoService.listadocumentos(0, 1000, pesquisa)
  
      if (DocumentosLista && Array.isArray(DocumentosLista.dados)) {
        const documentosAplanados = DocumentosLista.dados.flatMap((item) =>
          item.readManualArquivosDto.map((arquivo) => ({
            ...arquivo,
            equipamento: item.equipamento
          }))
        )
  
        const documentosFiltrados = documentosAplanados.filter((doc) =>
          doc.titulo.toLowerCase().includes(pesquisa.toLowerCase())
        )
        setTotalDados(documentosFiltrados.length)
        if (documentosFiltrados.length === 0) {
          setDocumentosAgrupados({})
        } else {
          const inicio = page * pageSize
          const fim = inicio + pageSize
          const documentosPaginados = documentosFiltrados.slice(inicio, fim)
          setDocumentosAgrupados(agruparDocumentosPorEquipamento(documentosPaginados))
        }
      } else {
        setDocumentosAgrupados({})
      }
    } catch (error) {
      setDocumentosAgrupados({})
    } finally {
      setLoading(false)
    }
  }
  
	const mudarPagina = (event, novaPagina) => {
		setPage(novaPagina)
	}

	const mudarTamanhoPagina = (event) => {
		setPageSize(parseInt(event.target.value, 10))
		setPage(0)
	}
	
  const delayPesquisa = useCallback(
    debounce((nome) => {
      setPage(0)
      buscarDocumentos(nome) 
    }, 700),
    []
  )
  
  const valorPesquisa = (nome) => {
    if (nome !== pesquisa) {
      setPesquisa(nome)
      delayPesquisa(nome) 
    }
  }
  
  const limparPesquisa = () => {
    setPesquisa('') 
    buscarDocumentos('') 
  }
  
  const handleBaixarArquivo = async (id, titulo) => {
	setEfeitoCarregando((prev) => ({ ...prev, [id]: true }));
  
	try {
	let arquivo = await documentoService.arquivodocumentos(id, titulo);
  
	if (!arquivo) {
		throw new Error('Arquivo não encontrado');
	}
  
	const blob = new Blob([arquivo], { type: 'application/pdf' });
	const fileURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement('a');
	tempLink.href = fileURL;
	tempLink.setAttribute('download', `${titulo}.pdf`);  
	tempLink.click();
  
	} catch (error) {
		return;
	} finally {
	setEfeitoCarregando((prev) => ({ ...prev, [id]: false }));
	}
  };

	const agruparDocumentosPorEquipamento = (documentos) => {
		return documentos.reduce((acc, doc) => {
			if (!acc[doc.equipamento]) {
				acc[doc.equipamento] = []
			}
			acc[doc.equipamento].push(doc)
			return acc
		}, {})
	}

	useEffect(() => {
		buscarDocumentos()
	}, [page, pageSize, pesquisa])

  useEffect(() => {
    const usuarioInfo = authService.getUserInfo().usuario;
    const permissaoAgendaGlobal = usuarioInfo.permissaoUsuario;
    
    const tecnicoPrestador = usuarioInfo.tecnicoPrestador;
    const empresa = usuarioInfo.empresa; 
    const tecnico = usuarioInfo.tecnico;
    let temPermissao = true; 
  
    if (empresa || tecnico) {
      temPermissao = permissaoAgendaGlobal?.some(permissao => permissao.permissoesLista?.includes('cod_manuais'));
    } else {
      temPermissao = true;
    }
  
    if (!temPermissao) {
      setTemPermissao(false);
    } else {
      setTemPermissao(true);
    }
    if (tecnicoPrestador && !temPermissao) {
		return;
    }
  
  }, []);	
	return (
		<>
			<MenuComponent />
			<CustomAlert
				open={abrirAlert}
				message={mensagemAlert}
				type={tipoAlert}
				onClose={() => setAbrirAlert(false)}
			/>
			{temPermissao ? (
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Manuais</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => {
								const userInfo = authService.getUserInfo()
								if (userInfo.usuario.revenda) {
									window.location.assign('/revenda');
								} else {
									window.location.assign('/agenda');
								}
							}}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span>{' '}
						{'>'} Manuais
					</h4>
					<Box display="flex" flexDirection="column" marginBottom={1}>
						<Grid container justifyContent="flex-end">
							<Grid item xs={isMobile ? 12 : 5}>
								<TextField
									variant="outlined"
									placeholder="Pesquisar"
									value={pesquisa}
									onChange={(e) => valorPesquisa(e.target.value)}
									className={classes.searchInput}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{pesquisa ? (
													<ClearIcon style={{ cursor: 'pointer' }} onClick={limparPesquisa} />
												) : (
													<SearchIcon className={classes.searchIcon} />
												)}
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
					</Box>
					<Paper className={classes.paper}>
						{loading ? (
							<span className={classes.infos}>
								<CircularProgress />
							</span>
						) : totalDados === 0 ? ( 
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ height: '100px' }}
							>
								<Typography variant="subtitle1" color="textSecondary">
									Não há Manuais.
								</Typography>
							</Grid>
						) : Object.keys(documentosAgrupados).length > 0 ? ( 
							Object.keys(documentosAgrupados).map((equipamento) => (
								<div key={equipamento}>
									<h2 className={classes.categoryTitle}>{equipamento}</h2>
									<Grid container>
										{documentosAgrupados[equipamento].map((d) => (
											<Grid container spacing={2} key={d.id} className={classes.item}>
												<Grid item xs={12} md={10} className={classes.row}>
													<span className={classes.avatar}>
														<PictureAsPdfIcon style={{ color: '#fff' }} />
													</span>
													<span style={{ flex: 1 }}>{d.titulo}</span>
												</Grid>
												<Grid item xs={12} md={2}>
													<BlueButton
														fullWidth
														onClick={() => handleBaixarArquivo(d.id, d.titulo)}
														style={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center'
														}}
													>
														{efeitoCarregando[d.id] ? (
															<>
																<CircularProgress size={20} style={{ marginRight: 10 }} />
																<span>Baixando...</span>
															</>
														) : (
															<>
																<GetAppIcon style={{ marginRight: 10 }} />
																<span>Baixar</span>
															</>
														)}
													</BlueButton>
												</Grid>
											</Grid>
										))}
									</Grid>
								</div>
							))
						) : (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								style={{ height: '100px' }}
							>
								<Typography variant="subtitle1" color="textSecondary">
									Não há Manuais.
								</Typography>
							</Grid>
						)}

						{Object.keys(documentosAgrupados).length !== 0 && (
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								spacing={1}
								style={{ marginTop: 10 }}
							>
								<Grid item>
									<CustomTablePagination
										count={totalDados}
										page={page}
										rowsPerPage={pageSize}
										onPageChange={mudarPagina}
										quantidadeItens={totalDados}
										onRowsPerPageChange={mudarTamanhoPagina}
									/>
								</Grid>
							</Grid>
						)}
					</Paper>
				</div>
			) : (
				<div style={{ margin: 30 }}>
					<h3 className={classes.tituloStyle}>Manuais</h3>
					<h4 className={classes.subtituloStyle}>
						<span
							onClick={() => {
								const userInfo = authService.getUserInfo()
								if (userInfo.usuario.gestor) {
									window.location.assign('/gestor')
								} else {
									window.location.assign('/agenda')
								}
							}}
							className={classes.linkStyle}
							style={{ cursor: 'pointer' }}
						>
							Home
						</span>{' '}
						{'>'} Manuais
					</h4>
					<Paper className={classes.paper}>
						<Grid container justifyContent="center">
							<h2 className={classes.mensagemStyle}>
								Você não tem permissão para acessar esta página!
							</h2>
						</Grid>
					</Paper>
				</div>
			)}
		</>
	)
}
